import Interceptor from './interceptor';

class Http401Interceptor extends Interceptor {

  onError(err) {
    const { response } = err;
    if (response && response.status === 401) {
      return this.client.AuthenticationService.logout();
    }

    return Promise.resolve(response);
  }
}

export default Http401Interceptor;
