import { Entity } from '../jsonapi';
import SurveyTranslation from './survey-translation';

export default class Survey extends Entity {
  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.translations': SurveyTranslation,
    }));
  }

}
