<template>
  <div>
    <p><strong>{{ ts.legend }}</strong></p>
    <div class="badges">
      <div class="vadis-molecule-risk-badge-level-1">
        <span>1</span>
      </div>
      <div class="vadis-molecule-risk-badge-level-2">
        <span>2</span>
      </div>
      <div class="vadis-molecule-risk-badge-level-3">
        <span>3</span>
      </div>
      <div class="vadis-molecule-risk-badge-level-4">
        <span>4</span>
      </div>
      <div class="vadis-molecule-risk-badge-level-5">
        <span>5</span>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.Legend">
{
  "legend": "Risk levels"
}
</i18n>

<script>
export default {
  name: 'Legend',
};

</script>
