<template>
  <div>
    <form
      id="login-form"
      class="vadis-organism-login"
      novalidate
      action="/api/v1/auth/internal/callback"
      method="post"
      @submit="validateCreds"
    >
      <h3>{{ ts.greeting }}</h3>
      <div :class="errorMessagesClass">
        <p>{{ ts.errors.wrongUserOrPassword }}</p>
      </div>
      <div v-if="loginInformation">
        <p>{{ loginInformation }}</p>
      </div>
      <div
        v-if="error"
        class="error-messages error-messages-visible"
      >
        <p>{{ errorMessage }}</p>
      </div>
      <div v-if="$featureEnabled('internalAuthentication')">
        <div :class="{ 'form-row vadis-form-field-standalone': true, invalid: $v.form.username.$error }">
          <input
            id="username-input"
            v-model.trim="form.username"
            name="login"
            :placeholder="ts.placeholders.username"
            :disabled="sending"
            @input="$v.form.username.$touch"
          >
          <p
            v-if="$v.form.username.$error"
            class="validation-error"
          >
            {{ ts.errors.usernameRequired }}
          </p>
        </div>
        <div class="form-row">
          <div :class="{ 'vadis-form-field-password-standalone': true, 'invalid-password': $v.form.password.$error }">
            <input
              id="password-input"
              v-model.trim="form.password"
              name="password"
              :type="passwordInputType"
              :placeholder="ts.placeholders.password"
              :disabled="sending"
              @input="$v.form.password.$touch"
            >
            <i
              :class="showPassword ? 'icon-Visible' : 'icon-Hidden'"
              @click="showPassword = !showPassword"
            />
          </div>
          <p
            v-if="$v.form.password.$error"
            class="validation-error"
          >
            {{ ts.errors.passwordRequired }}
          </p>
        </div>
      </div>
      <div class="form-row buttons">
        <button
          v-if="$featureEnabled('internalAuthentication')"
          type="submit"
          :disabled="$v.form.$invalid || sending"
        >
          {{ ts.buttons.login }}
        </button>
        <div
          v-if="$featureEnabled('internalAuthentication')"
          class="link"
        >
          <router-link :to="{ name: 'forgotten-password-screen' }">
            {{ ts.passwordForgotten }}
          </router-link>
        </div>
        <p
          v-if="$featureEnabled('internalAuthentication') && externalAuthMethods.length >= 1"
          class="or"
        >
          {{ ts.or }}
        </p>
        <p
          v-if="!$featureEnabled('internalAuthentication') && externalAuthMethods.length === 1"
          class="explanation"
        >
          {{ ts.oneChoice({name: externalAuthMethods[0].label}) }}
        </p>
        <p
          v-if="!$featureEnabled('internalAuthentication') && externalAuthMethods.length >1 "
          class="explanation"
        >
          {{ ts.multipleChoices }}
        </p>
        <div
          v-for="method in externalAuthMethods"
          :key="method.label"
        >
          <form
            v-if="method.type =='external'"
            :action="method.options.initUrl"
          >
            <button
              class="vadis-form-button-standard"
            >
              Login with {{ method.label }}
            </button>
          </form>
        </div>
      </div>
    </form>
  </div>
</template>

<i18n namespace="components.LoginForm">
{
  "greeting": "Please login",
  "errors": {
    "wrongUserOrPassword": "Wrong user name or password",
    "usernameRequired": "The username is required",
    "passwordRequired": "The password is required"
  },
  "apiErrorCodes": {
    "invalid_credentials": "Wrong user name or password"
  },
  "buttons": {
    "login": "Login"
  },
  "placeholders": {
    "username": "Username",
    "password": "Password"
  },
  "passwordForgotten": "Password forgotten ?",
  "or": "or",
  "oneChoice": "Your employer has required you to login with {name}",
  "multipleChoices": "Your employer has required you to login with one of this"
}
</i18n>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'LoginForm',
  props: {
    error: {
      type: String,
      default: null,
      required: false,
    },
    loginInformation: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    showPassword: false,
    form: {
      username: '',
      password: '',
    },
    sending: false,
    authenticationSuccess: null,
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    errorMessage() {
      return this.ts.apiErrorCodes[this.error] || this.error;
    },
    errorMessagesClass() {
      return {
        'error-messages': true,
        'error-messages-visible': (this.authenticationSuccess === false),
      };
    },
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
    externalAuthMethods() {
      return this.$flairClient.AppService.instance.authMethods
        .filter(m => m.type === 'external');
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
      this.form.password = null;
    },
    validateCreds(e) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        return true;
      }
      e.preventDefault();
    },
  },
};
</script>
