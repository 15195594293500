import { Document } from '../entities';
import Downloadable from '../utils/downloadable';
import Service from './service';
import axios from 'axios';

export default class DownloadService extends Service {
  constructor({ client }) {
    super({ client });
  }

  download(doc) {
    if (!(doc instanceof Document)) {
      throw new Error('DownloadService#download expects a Document');
    }
    const { api } = this.client;
    const token = this.client.getStorage().get('token');
    return axios({
      url: `${api.baseUrl + doc.data.links.self}/`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token,
      },
    }).then((response) => {
      const document = new Downloadable(response.data, doc.name);
      document.download();
    });
  }
}
