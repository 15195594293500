<template>
  <div class="vadis-organism-geographical-risk-info">
    <div class="country-name">
      <div>
        <CountryFlag :country="riskIndicator.country" />
        <span
          v-if="back"
          @click="$emit('back')"
        ><a>{{ ts.back }}</a></span>
      </div>
    </div>
    <div
      v-if="riskIndicator"
      class="current-risk"
    >
      <div :class="'number number-' + currentRisk">
        {{ rawRisk || '-' }}
      </div>
      <div class="name">
        {{ ts.riskNames[currentRisk] }}
      </div>
    </div>
    <!-- <GeographicalRiskHistory
      :history="riskIndicator.history"
      class="chart"
    /> -->
  </div>
</template>

<i18n namespace="components.GeographicalRiskInfo">
{
  "back": "back",
  "riskNames": [
    "Low Risk",
    "Low Risk",
    "Low Risk",
    "Low Risk",
    "Medium Risk",
    "Medium Risk",
    "Medium Risk",
    "High Risk",
    "High Risk",
    "High Risk"
  ]
}
</i18n>

<script>
import CountryFlag from '@/components/CountryFlag';

export default {
  name: 'GeographicalRiskInfo',
  components: {
    CountryFlag,
  },
  props: {
    back: {
      type: Boolean,
      default: false,
      required: false,
    },
    riskIndicator: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    currentRisk: function() {
      return this.riskIndicator.score;
    },
    rawRisk: function() {
      return this.riskIndicator.rawScore;
    },
  },
};

</script>
