import _ from 'lodash';
import { Entity } from '../jsonapi';
import AppInstance from './app-instance';
import Attachment from './attachment';
import Company from './company';
import DataProvider from './dataProvider';
import MediaLink from './mediaLink';
import MonitoredCompany from './monitoredCompany';
import RiskIndicator from './riskIndicator';
import RiskProfile from './riskProfile';
import RiskAnalysis from './riskAnalysis';
import LegalLink from './legal-link';
import Private from './private';
import Person from './person';
import UserProfile from './user-profile';
import Department from './department';
import Team from './team';
import Document from './document';
import DueDiligence from './dueDiligence';
import Timeline from './timeline';
import Member from './member';
import SurveySubmission from './survey-submission';
import SurveyTranslation from './survey-translation';
import Survey from './survey';
import DueDiligenceRole from './dueDiligenceRole';
import EnabledRiskIndicator from './enabledRiskIndicator';

const Entities = {
  AppInstance: AppInstance,
  Attachment: Attachment,
  Company: Company,
  DataProvider: DataProvider,
  Department: Department,
  'DueDiligence.Role': DueDiligenceRole,
  'DueDiligence.Document': Document,
  DueDiligence: DueDiligence,
  EnabledRiskIndicator: EnabledRiskIndicator,
  LegalLink: LegalLink,
  MediaLink: MediaLink,
  Member: Member,
  MonitoredCompany: MonitoredCompany,
  Person: Person,
  RiskIndicator: RiskIndicator,
  RiskProfile: RiskProfile,
  RiskAnalysis: RiskAnalysis,
  Private: Private,
  SurveySubmission: SurveySubmission,
  Survey: Survey,
  SurveyTranslation: SurveyTranslation,
  Team: Team,
  Timeline: Timeline,
  'User.Profile': UserProfile,
};

_.each(Entities, (clazz, name) => Entity.registerType(name, clazz));

export {
  AppInstance,
  Attachment,
  Company,
  DataProvider,
  DueDiligenceRole,
  Department,
  Document,
  DueDiligence,
  EnabledRiskIndicator,
  LegalLink,
  MediaLink,
  Member,
  MonitoredCompany,
  Person,
  Private,
  RiskIndicator,
  RiskProfile,
  RiskAnalysis,
  SurveySubmission,
  Survey,
  SurveyTranslation,
  Team,
  Timeline,
  UserProfile,

};
