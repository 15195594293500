<template>
  <div class="vadis-organism-openable-media-link-list">
    <div />
    <div class="content">
      <div
        v-if="mediaLinks.length"
        class="media-link-list table-border"
      >
        <table class="table-hover">
          <thead>
            <tr>
              <th>{{ ts.th.mediaLinks }}</th>
              <th>{{ ts.th.publicationDate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(link, i) in orderedLinks"
              :key="i"
            >
              <td>
                <a
                  :href="link.ogUrl"
                  target="_blank"
                  class="content"
                >
                  <a>{{ link.ogUrl }}</a>
                  <i class="icon-ExternalLink" />
                </a>
              </td>
              <td> {{ link.ogPublishDate | humanDate }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="vadis-organism-media-link-empty"
      >
        <img src="../../assets/tab_empty.svg">
        <h6>{{ ts.noInformation }}</h6>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.MediaLinkList">
{
  "th": {
    "mediaLinks": "Media Links",
    "publicationDate": "Publication Date"
  },
  "published": "Published",
  "noInformation": "No information available."
}
</i18n>

<script>
import _ from 'lodash';

export default {
  name: 'MediaLinkList',
  props: {
    mediaLinks: {
      type: Array,
      default: null,
      required: false,
    },
  },
  computed: {
    orderedLinks() {
      return _.orderBy(this.mediaLinks, ['ogPublishDate'], ['desc']);
    },
  },
  methods: {
    open(url, isActive) {
      if (isActive) {
        window.open(url, '_blank');
      }
    },
  },
};

</script>
