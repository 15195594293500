export default class LocalStorage {
  get(key, def = undefined) {
    return window.localStorage.getItem(key) || def;
  }
  set(key, value) {
    if (value === undefined || value === null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, value);
    }
    return this;
  }
}
