import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';

Vue.use(Router);

export function createRouter({ flairClient, featureFlags } = {}) {

  const router = new Router({
    // https://router.vuejs.org/guide/essentials/history-mode.html
    mode: 'history',
    routes: [
      {
        path: '/login',
        name: 'login-screen',
        component: Login,
        meta: {
          public: true,
        },
      },
      {
        path: '/configuration',
        name: 'configuration-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Configuration.vue'),
        meta: {
          enabled: () => {
            if (featureFlags.isEnabled('configuration')) {
              return featureFlags.isEnabled('userManagement');
            }
            return false;
          },
        },
      },
      {
        path: '/',
        name: 'search-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
      },
      {
        path: '/help',
        name: 'help-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Help.vue'),
      },
      {
        path: '/customization',
        name: 'customization-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Customization.vue'),
      },
      {
        path: '/surveys/:secretkey',
        name: 'surveys-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Surveys.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: '/company/:id/due-diligence/:subtab?',
        name: 'company-due-diligence-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/CompanyDetails.vue'),
        beforeEnter: (to, from, next) => {
          to.params.tab = 'due-diligence';
          next();
        },
      },
      {
        path: '/company/:id/s/pdf',
        name: 'company-pdf-details-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/CompanyPDF.vue'),
      },
      {
        path: '/company/:id/:tab/:subtab?',
        name: 'company-details-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/CompanyDetails.vue'),
      },
      {
        path: '/person/:id/:tab',
        name: 'person-details-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/PersonDetails.vue'),
      },
      {
        path: '/due-diligence/:id',
        name: 'due-diligence-details-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/due-diligence/DueDiligenceDetails.vue'),
      },
      {
        path: '/due-diligences/',
        name: 'my-due-diligence-list-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/due-diligence/MyDueDiligenceList.vue'),
      },
      {
        path: '/my/portfolio',
        name: 'my-portfolio-screen',
        component: () => {
          if (!featureFlags.isEnabled('teamPortfolio')) {
            return import(/* webpackChunkName: "search" */ './views/MyPortfolio.vue');
          } else {
            return import(/* webpackChunkName: "search" */ './views/TeamPortfolio.vue');
          }
        },
        meta: {
          enabled: () => {
            return featureFlags.isEnabled('portfolio');
          },
        },
      },
      {
        path: '/my/settings/:tab?',
        name: 'my-settings-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/MySettings.vue'),
        meta: {
          enabled: () => {
            return featureFlags.isEnabled('teamManagement') || featureFlags.isEnabled('userManagement');
          },
        },
      },
      {
        path: '/password-reset/:token',
        name: 'reset-password-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/ResetPassword.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: '/password-forgotten',
        name: 'forgotten-password-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/ForgottenPassword.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: '/subscribe/:token',
        name: 'subscribe-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/CreateAccount.vue'),
        meta: {
          public: true,
        },
      },
      {
        path: '/404',
        name: 'error-404-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Error404.vue'),
      },
      {
        path: '*',
        name: 'catch-404-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/Error404.vue'),
      },
      {
        path: '/my/portfolio/s/pdf',
        name: 'my-portfolio-pdf-screen',
        component: () => import(/* webpackChunkName: "search" */ './views/MyPortfolioPDF.vue'),
      },
    ],
  });

  flairClient.on('authentication:loggedOut', () => {
    // Intentionally not using the router here
    // Forcing the URL will provoke a total refresh of the browser, thus
    // restarting the whole app and clearing memory/state
    // Everywhere else, use the router
    window.location = process.env.VUE_APP_BASEURL || '/';
  });

  const originalPush = Router.prototype.push;
  Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
  };

  router.beforeEach((to, from, next) => {
    document.body.id = to.name;
    const isPublic = to.matched.some(record => record.meta.public);
    const loggedIn = !!flairClient.AuthenticationService.isLoggedIn();
    let isEnabled = (to.meta.enabled === undefined || to.meta.enabled);

    if (!isPublic && !loggedIn) {
      return next({
        query: { redirect: to.name !== 'search-screen' ? to.path : null },
        path: '/login',
      });
    }

    // Ensure the profile is loaded if needed
    const p = loggedIn ? flairClient.AuthenticationService.loadProfile() : Promise.resolve();
    p
      .then(() => {
        if (typeof isEnabled === 'function') {
          isEnabled = isEnabled();
        }

        if (!isEnabled) {
          return next({
            path: '/404',
          });
        }
        next();
      });
  });
  return router;
}
