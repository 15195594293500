import moment from 'moment';

export default (Vue, flairClient) => {
  const dateTime = flairClient.ts('dateTime');

  Vue.filter('birthDate', birthDate);
  Vue.filter('birthDateWithAge', birthDateWithAge);
  Vue.filter('humanDate', humanDate);
  Vue.filter('humanDateYear', humanDateYear);
  Vue.filter('humanDateDay', humanDateDay);
  Vue.filter('humanDateMonth', humanDateMonth);
  Vue.filter('humanDateMonthYear', humanDateMonthYear);
  Vue.filter('humanDateHour', humanDateHour);
  Vue.filter('shortDate', shortDate);
  Vue.filter('shortDateHour', shortDateHour);

  function birthDate(value, withAge = false) {
    if (!value) {
      return '';
    }
    const date = new Date();
    const age = Math.abs(date.getUTCFullYear() - value);

    if (withAge) {
      return `${value} (${age} ${dateTime.years})`;
    } else {
      return `${value}`;
    }
  }

  function birthDateWithAge(value) {
    return birthDate(value, true);
  }

  function humanDate(value) {
    if (value) {
      return `${humanDateDay(value)} ${humanDateMonth(value)} ${humanDateYear(value)}`;
    }
    else {
      return '-';
    }
  }

  function humanDateYear(value) {
    if (value) {
      return moment(value).format('YYYY');
    }
    else {
      return '-';
    }
  }

  function humanDateDay(value) {
    if (value) {
      return moment(value).format('DD');
    }
    else {
      return '-';
    }
  }

  function humanDateMonth(value) {
    if (value) {
      const monthName = moment(value).format('MMMM');
      return dateTime.months[monthName];
    }
    else {
      return '-';
    }
  }

  function humanDateMonthYear(value) {
    if (value) {
      return `${humanDateMonth(value)} ${humanDateYear(value)}`;
    }
    else {
      return '-';
    }
  }

  function humanDateHour(value) {
    if (value) {
      return `${humanDate(value)} ${moment(value).format(', HH:mm')}`;
    }
    else {
      return '-';
    }
  }

  function shortDate(value) {
    if (!value) {
      return '-';
    }
    return moment(value).format('DD/MM/YYYY');
  }

  function shortDateHour(value) {
    if (!value) {
      return '-';
    }
    return `${moment(value).format('DD/MM/YYYY, HH:mm')}`;
  }
};
