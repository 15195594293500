export default (Vue, flairClient) => {

  Vue.filter('truncate', truncate);
  Vue.filter('truncateWord', truncate);

  function truncate(text, ln) {
    if (text.length <= ln) {
      return text;
    }

    const trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
    const reg = new RegExp(`(?=[${trimmable}])`);

    if (reg.test(text)) {
      const words = text.split(reg);
      let count = 0;
      if (words[0].length > ln) {
        return truncateWord(words[0], ln);
      }
      return (
        `${words
          .filter((word) => {
            count += word.length;
            return count <= ln;
          })
          .join('')}...`
      );
    } else {
      return truncateWord(text, ln);
    }
  }

  function truncateWord(word, ln) {
    const letters = word.split('');
    let count = 0;
    return (
      `${letters
        .filter(() => {
          count++;
          return count <= ln - 3;
        })
        .join('')}...`
    );
  }

};
