import Api from './api';
import { Http401Interceptor, Autolinker, AutoRelationship, TokenInjector, ProviderInjector } from './interceptors';

export default {
  createApi: (client) => {
    const tokenInjector = new TokenInjector({ client, tokenField: 'Authorization' });
    const providerInjector = new ProviderInjector({ client });
    const http401Interceptor = new Http401Interceptor({ client });
    const autolinker = new Autolinker({ client, force: false });
    const autoRelationship = (name, suffix, type) => new AutoRelationship({ client, name, suffix, type });
    const autoLinkRelationship = (name, type) => new AutoRelationship({ client, name, type });

    const apiDef = {
      baseUrl: '/api/',
      interceptors: [
        http401Interceptor,
        providerInjector,
      ],
      services: {
        RiskService: {
          path: 'v1/risks',
          endpoints: {
            getRiskTreeCompany: {
              path: '/tree/company/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },

          },
        },
        SupportService: {
          path: 'v1/support',
          endpoints: {
            reportIssue: {
              path: '/report-issue/',
              method: 'POST',
            },

          },
        },
        AppService: {
          path: 'v1/app',
          endpoints: {
            getInstance: {
              path: '/instance',
              method: 'GET',
            },
            getVersion: {
              path: '/version',
              method: 'GET',
            },
          },
        },
        AdminService: {
          path: 'v1/admin/',
          endpoints: {
            getDataConsumption: {
              path: 'consumption/:year/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            getDataConsumptionMonthly: {
              path: 'consumption/:year/:month/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            getSubscription: {
              path: 'subscription/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            getOrganizationInfo: {
              path: 'organization/info/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            getDepartments: {
              path: 'departments/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            createDepartment: {
              path: 'departments/',
              method: 'POST',
              interceptors: [
                tokenInjector,
              ],
            },
            updateDepartment: {
              path: 'departments/:id/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            removeDepartment: {
              path: 'departments/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            getTeams:{
              path: 'teams/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            createTeam: {
              path: 'teams/',
              method: 'POST',
              interceptors: [
                tokenInjector,
              ],
            },
            removeTeam: {
              path: 'teams/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            updateTeam: {
              path: 'teams/:id/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            getUsers: {
              path: 'users/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
            deleteUser: {
              path: 'users/:id',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            createUser: {
              path: 'users/',
              method: 'POST',
              interceptors: [
                tokenInjector,
              ],
            },
            updateUser: {
              path: 'users/:id/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            removeUserFromTeam: {
              path: 'teams/:teamId/users/:userId/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            sendUserInvite: {
              path: 'users/:id/invite/',
              method: 'POST',
              interceptors: [
                tokenInjector,
              ],
            },
          },
        },
        AuthenticationService: {
          path: 'v1/auth/',
          endpoints: {
            authenticate: {
              path: 'internal/callback',
              method: 'POST',
              interceptors: [
              ],
            },
            forgotPassword: {
              path: 'pwdreset/request/',
              method: 'POST',
              interceptors: [
              ],
            },
            validateToken: {
              path: 'pwdreset/validate/',
              method: 'GET',
              interceptors: [
              ],
            },
            resetPassword: {
              path: 'pwdreset/',
              method: 'POST',
              interceptors: [
              ],
            },
            activateUser: {
              path: 'user/activate/',
              method: 'PUT',
              interceptors: [
              ],
            },
          },
        },
        OpenGraphService: {
          path: 'v1/opengraph/',
          endpoints: {
            getArticle: {
              path: ':url/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        MyService: {
          path: 'v1/my',
          endpoints: {
            searchHistory: {
              path: 'search-history/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            pushToHistory: {
              path: 'search-history/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            browseHistoryCompanies: {
              path: 'browse-history/companies/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            browseHistoryPeople: {
              path: 'browse-history/people/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            pushToBrowseHistoryPeople: {
              path: 'browse-history/people/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            pushToBrowseHistory: {
              path: 'browse-history/companies/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            removeFromBrowseHistory: {
              path: 'browse-history/companies/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            profile: {
              path: 'profile/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            editProfile: {
              path: 'profile/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            changePassword: {
              path: 'password/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getPreferences: {
              path: 'preferences/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            setPreferences: {
              path: 'preferences/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getMyDueDiligences: {
              path: 'due-diligences/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getMyTeamMembers: {
              path: 'team-members/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getMyTeams: {
              path: 'teams/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getTeamStatisticsByIndicator: {
              path: 'teams/:id/portfolio/statistics/:indicator/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getTeamPortfolioSubjects: {
              path: 'teams/:id/portfolio/subjects/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            addTeamPortfolioSubject: {
              path: 'teams/:id/portfolio/subjects/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            deleteTeamPortfolioSubject: {
              path: 'teams/:id/portfolio/subjects/:subjectId/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getTeamPortfolioGeographicalPresence: {
              path: 'teams/:id/portfolio/geographical-presence/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getTeamPortfolioGeographicalCountryPresence: {
              path: 'teams/:id/portfolio/geographical-presence/:country/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        EntitiesService: {
          path: 'v1/entities/',
          endpoints: {
            searchCompanies: {
              path: 'companies/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            searchPeople: {
              path: 'people/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompany: {
              path: 'companies/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
                autoRelationship('shareholders', 'neighborhood/shareholders/', 'Collection'),
                autoRelationship('subsidiaries', 'neighborhood/subsidiaries/', 'Collection'),
                autoRelationship('relatedPeople', 'neighborhood/related-people/', 'Collection'),
                autoRelationship('hierarchicalGraph', 'hierarchical-graph/', 'Entity'),
              ],
            },
            getCompanyShareholders: {
              path: 'companies/:id/neighborhood/shareholders/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanySubsidiaries: {
              path: 'companies/:id/neighborhood/subsidiaries/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyRelatedPeople: {
              path: 'companies/:id/neighborhood/related-people/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyDueDiligences: {
              path: 'companies/:id/due-diligences/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyLastDueDiligence: {
              path: 'companies/:id/due-diligences/last/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            startDueDiligence: {
              path: 'companies/:id/due-diligences/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyRisk: {
              path: 'companies/:id/risk-profile/indicators/:risk/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyHistoricalCreditRisk: {
              path: 'companies/:id/history/:year/credit-risk/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getCompanyHistoricalRiskIndicator: {
              path: 'companies/:id/history/:year/risk-profile/indicators/:risk/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
                autoRelationship('mediaLinks', 'media-links/', 'Collection'),
              ],
            },
            getCompanyHistoricalRiskIndicatorMediaLinks: {
              path: 'companies/:id/history/:year/risk-profile/indicators/:risk/media-links/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getHierarchicalGraph: {
              path: 'companies/:id/hierarchical-graph/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getGroup: {
              path: 'groups/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getGroupCompaniesByCountry: {
              path: 'groups/:id/companies/:country/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getPerson: {
              path: 'people/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getPersonHistoricalRiskIndicator: {
              path: 'people/:id/history/:year/risk-profile/indicators/:risk/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getPersonRelatedCompanies: {
              path: 'people/:id/neighborhood/related-companies/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getEcovadisRiskRatingById: {
              path: 'companies/:id/ecovadis/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        TransparencyService: {
          path: 'v1/transparency/',
          endpoints: {
            getGeographicalRisk: {
              path: 'risks/geographical/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getGeographicalRiskHistory: {
              path: 'risks/geographical/:country/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        RiskAnalysisService: {
          path: 'v1/risk-analysis',
          endpoints: {
            getAnalysis: {
              path: ':id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        DueDiligenceService: {
          path: 'v1/due-diligences',
          endpoints: {
            getDueDiligenceById: {
              path: ':id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            addAttachments: {
              path: 'attachments/',
              method: 'POST_FORM',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            removeAttachments: {
              path: 'attachments/:guid/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            postComment: {
              path: '/:id/actions/comment/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            reviseRisk: {
              path: '/:id/actions/revise-risk/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            makeDecision: {
              path: '/:id/actions/make-decision/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            inviteMembers: {
              path: '/:id/actions/invite-members/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            requestInfo: {
              path: '/:id/actions/request-info/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            getSurveySubmissionById: {
              path: '/survey/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            getSurveySubmissionBySecretKey: {
              path: '/surveys/by-secret-key/:secretkey/submissions/last/',
              method: 'GET',
              interceptors: [
                autolinker,
                autoLinkRelationship('company', 'Company'),
              ],
            },
            updateSurveySubmission: {
              path: '/surveys/by-secret-key/:secretkey/submissions/last/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            getDueDiligenceRoles: {
              path: '/roles/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
          },
        },
        SurveysService: {
          path: 'v1/surveys',
          endpoints: {
            getSurveys: {
              path: '/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getSurvey: {
              path: '/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            updateSurvey: {
              path: '/:id/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            createSurvey: {
              path: '/',
              method: 'POST_FORM',
              interceptors: [
                tokenInjector,
              ],
            },
            removeSurvey: {
              path: '/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            getSurveyTranslation: {
              path: '/translations/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            addSurveyTranslation: {
              path: '/translations/',
              method: 'POST_FORM',
              interceptors: [
                tokenInjector,
              ],
            },
            updateSurveyTranslation: {
              path: '/translations/:id/',
              method: 'PUT_FORM',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            removeSurveyTranslation: {
              path: '/translations/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
          },
        },
        DataProviderService: {
          path: 'v1/data-providers',
          endpoints: {
            getDataProviders: {
              path: '/risk-indicators/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            getEnabledIndicators: {
              path: '/risk-indicators/enabled/',
              method: 'GET',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            updateEnabledIndicators: {
              path: '/risk-indicators/enabled/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            createCustomRiskIndicator: {
              path: '/custom/risk-indicators/',
              method: 'POST',
              interceptors: [
                tokenInjector,
                autolinker,
              ],
            },
            removeCustomRiskIndicator: {
              path: '/custom/risk-indicators/:id/',
              method: 'DELETE',
              interceptors: [
                tokenInjector,
              ],
            },
            updateCustomRiskIndicator: {
              path: '/custom/risk-indicators/:id/',
              method: 'PUT',
              interceptors: [
                tokenInjector,
              ],
            },
            ensureIndicatorNameUnused: {
              path: '/custom/risk-indicators/:id/',
              method: 'GET',
              interceptors: [
                tokenInjector,
              ],
            },
          },
        },
      },
    };
    return Api.fromJsonDef(apiDef, client);
  },
};
