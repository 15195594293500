import Interceptor from './interceptor';
import ApiUtils from '../utils';

class AutoRelationship extends Interceptor {

  /**
   * Creates a new automatic relationship on an entity
   * @param client: a FlairClient instance
   * @param name: the name of the relationship
   * @param type: a string "Entity" or "Collection"
   * @param suffix: optional API suffix to load the relationship. If missing, AutoRelationship will be looking for the relationship link in the links JSONAPI Section
   */
  constructor({ client, name, suffix, type }) {
    super({ client });
    this.name = name;
    this.type = type;
    this.suffix = suffix;
  }

  onAfterRequest(response, endpoint) {
    if (response.status < 200 || response.status > 299) {
      return Promise.resolve(response);
    }
    const envelope = response.data;
    if (!envelope.data) {
      return Promise.resolve(response);
    }
    envelope.data.relationships = envelope.data.relationships || {};
    if (envelope.data.relationships[this.name]) {
      console.warn(`Relationship ${this.name} not found.`);
      return Promise.resolve(response);
    }
    const apiBaseUrl = endpoint.api.baseUrl;
    const base = response.config.url.replace(apiBaseUrl, '/');
    let linkUrl;
    if (this.suffix) {
      linkUrl = ApiUtils.concat(base, this.suffix);
    } else {
      linkUrl = envelope.data.links[this.name];
    }
    envelope.data.relationships[this.name] = {
      data: { type: this.type },
      links: { self: linkUrl },
    };
    return Promise.resolve(response);
  }
}

export default AutoRelationship;
