<template>
  <div>
    <button
      class="vadis-form-button-main-icon support"
      @click="openSupport()"
    >
      <i class="icon-Info" /> {{ ts.support }}
    </button>
    <SupportModalForm
      ref="supportModal"
    />
  </div>
</template>

<i18n namespace="components.SupportButton">
{
  "support": "support"
}
</i18n>

<script>
import SupportModalForm from '@/components/SupportModalForm';

export default {
  name: 'SupportButton',
  components: {
    SupportModalForm,
  },
  methods: {
    openSupport() {
      this.$refs.supportModal.open();
    },
  },
};
</script>
