<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="transition"
    >
      <div class="vadis-style-global-DialogBg" />
      <div :class="'vadis-organism-more-info-indicator ' + params.name">
        <RiskInfoNav
          :name="params.name"
          :active-nav="params.activeNav"
          :group="isGroup"
          @click="setInfo($event)"
          @close="close()"
        />
        <RiskAnalysisList
          v-if="currentNav === 'analysis' && (params.name === 'pep' || params.name === 'adverse_media' || params.name === 'sanction')"
          :entity="params.entity"
          :name="params.name"
        />
        <GeographicalRisk
          v-if="currentNav === 'company'"
          :company="params.entity"
        />
        <GroupGeographicalRisk
          v-if="currentNav === 'group'"
          :primary-group="params.entity.primaryGroup"
          @click="openDetails($event)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import RiskInfoNav from '@/components/risk/RiskInfoNav';
import RiskAnalysisList from '@/components/risk/RiskAnalysisList';
import GeographicalRisk from '@/components/geographical/GeographicalRisk';
import GroupGeographicalRisk from '@/components/GroupGeographicalRisk';

// The instance of our Singleton component
let _instance;

export default {
  name: 'RiskInfo',
  components: {
    RiskInfoNav,
    RiskAnalysisList,
    GeographicalRisk,
    GroupGeographicalRisk,
  },
  data: () => ({
    isOpen: false,
    currentNav: null,
    params: null,
  }),
  computed: {
    isGroup() {
      return this.params.entity && !this.params.entity.primaryGroup;
    },
  },
  created() {
    if (_instance) {
      throw new Error('RiskInfo is not supposed to be included more than once');
    }
    _instance = this;
  },
  destroyed() {
    _instance = null;
  },
  /** PUBLIC API */
  open(params) {
    return _instance.open(params);
  },
  close() {
    return _instance.close();
  },
  methods: {
    setInfo(label) {
      this.currentNav = label;
    },
    openDetails(value) {
      this.$router.push({
        name: 'company-details-screen',
        params: { id: value.id, tab: 'summary' },
      });
      this.close();
    },
    open(params) {
      this.params = params;
      this.currentNav = this.params.activeNav;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
