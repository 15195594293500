import { Entity } from '../jsonapi';
import Service from './service';
import { UserProfile } from '../entities';
import { helper } from './helper';
import emit from '../decorators/emit';
import cookies from 'js-cookie';

export default class AuthenticationService extends Service {
  constructor({ client }) {
    super({ client });
    this._profile = null;
    this.on('loggedOut', () => {
      this._profile = null;
    });
  }

  /**
   * At initialization tokens found in cookies have priority over existing
   * tokens present in localStorage (and will therefore overwrite them).
   */
  initialize() {
    const token = cookies.get('x-flair-token');
    if (token) {
      return this.setToken(token);
    }
    return Promise.resolve();
  }

  ApiService = this.client.api.AuthenticationService;

  get me() {
    return this._profile;
  }

  loadProfile() {
    if (!this.isLoggedIn()) {
      return Promise.reject(new Error('User not authenticated'));
    }
    if (this._profile) {
      return Promise.resolve(this._profile);
    }
    return this.client.api.MyService.profile()
      .then((res) => {
        this._profile = Entity.factor(res, null, UserProfile);
        return this._profile;
      })
      .catch((e) => {
        this.logout();
        throw e;
      });
  }

  isLoggedIn() {
    return !!this.client.getStorage().get('token');
  }

  @emit('loggedIn')
  login(username, pwd) {
    const { api } = this.client;
    return api.AuthenticationService
      .authenticate({
        login: username,
        password: pwd,
      })
      .then((res) => {
        return this.setToken(res.access_token, res.token_type);
      })
      .then(() => true);
  }

  @emit('loggedOut')
  logout() {
    this.client.getStorage().set('token', null);
    cookies.remove('x-flair-token');
    return true;
  }

  setToken(token, type = 'Bearer') {
    this.client.getStorage().set('token', `${type} ${token}`);
    return this.loadProfile();
  }

  forgottenPassword = helper(this.ApiService.forgotPassword);

  resetPassword = helper(this.ApiService.resetPassword);

  getTokenValidity = helper(this.ApiService.validateToken);

  activateUser = helper(this.ApiService.activateUser);
}
