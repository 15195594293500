/**
 * Global mixin bringing translations blocks, processed by webpack, to components
 * as a global `ts` object.
 *
 * Usage:
 *
 * In your .vue file, include a <i18n> block, containing a valid JSON object containing translations
 *
 * <i18n namespace="components.NameOfMyComponent">
 * {
 *   "title": "Some default title",
 *   "subobject": {
 *     "subkey": "other translation"
 *   }
 * }
 * </i18n>
 *
 * These translations will be accessible through the `ts` object, exposed in `data`
 *
 */
export default {
  data: function() {
    if (!this.$options._i18n) {
      return {};
    }
    const { translations, namespace } = this.$options._i18n;
    return {
      ts: this.$flairClient.ts(namespace, translations),
    };
  },
};
