<template>
  <div v-if="isOpen">
    <div
      v-if="dimBackground"
      class="vadis-style-global-DialogBg-error"
    />
    <div class="vadis-modal-error">
      <slot name="header">
        <h6>{{ ts.error.oops }}</h6>
      </slot>

      <slot>
        <p>
          {{ ts.error.somethingBadHappened }}
        </p>
      </slot>
      <div
        class="clip-Board"
        @click="downloadtextFile()"
      >
        <i class="icon-Clipboard" />
        <a>{{ ts.download }}</a>
      </div>
      <div class="buttons">
        <slot name="buttons">
          <button
            type="button"
            name="button"
            class="vadis-form-button-standard-small"
            @click="close();"
          >
            {{ ts.ok }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.ErrorModal">
{
  "error": {
    "oops": "Oops",
    "somethingBadHappened": "An error occured. We are doing our best to remedy this as quickly as possible."
  },
  "download": "Download bug details",
  "ok": "Ok"
}
</i18n>

<script>
import _ from 'lodash';
import { version } from '@/constants';

export default {
  name: 'ErrorModal',
  props: {
    dimBackground: {
      type: Boolean,
      default: true,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  created() {
    if (this.startOpen) {
      this.isOpen = true;
    }
  },
  methods: {
    open(event) {
      const instance = this.$flairClient.AppService.instance;
      const error = {
        apiUrl: _.get(event, 'reason.response.config.url'),
        msg: _.get(event, 'reason.response.data.msg'),
        stack: _.get(event, 'reason.stack'),
        apiMethod: _.get(event, 'reason.config.method'),
        pageUrl: _.get(event, 'target.location.href'),
        component: _.get(event, 'currentTarget.document.activeElement.id'),
        dataProvider: _.get(event, 'target.localStorage.provider'),
        frontendVersion: version,
        backendVersion: instance.backendVersion,
        dataVersion: instance.dataVersion,
        apiEnvironment: instance.environment,
        apiServer: instance.server,
        dataSource: instance.source,
        articlesSource: instance.articlesSource,
      };
      this.clipBoard = Object.keys(error)
        .reduce((str, key) => {
          const value = error[key];
          return `${str}${key}: ${value}\n`;
        }, '');
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    downloadtextFile() {
      const filename = 'Flair - Bug details';
      const text = this.clipBoard;
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
