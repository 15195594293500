import { Entity } from '../jsonapi';
import Team from './team';

export default class UserProfile extends Entity {

  get initials() {
    const names = this.fullName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  get fullName() {
    return `${this.attributes.firstName} ${this.attributes.lastName}`;
  }

  hasTeam() {
    return this.teams.length > 0;
  }

  isTeamManager() {
    return (this.teams || []).find((team) => {
      return team.isManager === true;
    });
  }

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.teams': Team,
    }));
  }
}

const UNKNOWN_USER_PROFILE = Entity.factor({
  data: {
    id: null,
    type: 'User.Profile',
    attributes: {
      lastName: 'Unknown',
      firstName: 'Member',
    },
  },
}, UserProfile);

export { UNKNOWN_USER_PROFILE };
