<template>
  <span>{{ label }}</span>
</template>

<i18n namespace="components.AnalysisSourceInfo">
{
  "unknown": "Unknown compliance source",
  "idUnknown": "According to {ds}",
  "known": "{ds} entry #{id}"
}
</i18n>

<script>

const isUnknown = (x) => {
  return !x || x.match(/unknown/);
};

export default {
  name: 'AnalysisSourceInfo',
  props: {
    analysis: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    label() {
      const a = this.analysis;
      const dsUnknown = isUnknown(a.dataSource);
      const dsIdUnknown = isUnknown(a.dataSourceId);
      if (dsUnknown) {
        return this.ts.unknown;
      } else if (dsIdUnknown) {
        return this.ts.idUnknown({ ds: a.dataSource });
      } else {
        return this.ts.known({ ds: a.dataSource, id: a.dataSourceId });
      }
    },
  },
};
</script>
