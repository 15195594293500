<template>
  <div
    id="app"
    class="vadis-html-style"
  >
    <router-view />
    <SupportButton v-if="isLogged" />
    <ErrorModal
      ref="oopsModal"
      class="oops-modal"
    />
    <RiskInfo />
    <ConfirmationModal />
  </div>
</template>

<style lang="scss">
  @import 'flairui/assets/app/stylesheets/flair.scss';
  @import '~flag-icon-css/css/flag-icon.css';
  @import '~leaflet/dist/leaflet.css';
</style>

<script>
import { version } from '@/constants';
import FlairClient from '@/flair-client';
import ErrorModal from '@/components/ErrorModal';
import RiskInfo from '@/components/risk/RiskInfo';
import ConfirmationModal from '@/components/ConfirmationModal';
import SupportButton from '@/components/SupportButton';

export default {
  name: 'App',
  components: {
    ErrorModal,
    RiskInfo,
    ConfirmationModal,
    SupportButton,
  },
  data: () => ({
    FlairClient,
    version,
    apiVersion: null,
    error: null,
    errorClipBoard: null,
  }),
  computed: {
    isLogged() {
      return this.$flairClient.AuthenticationService.isLoggedIn();
    },
  },
  created() {
    // Global unhandled-error handler, to display
    window.addEventListener('unhandledrejection', (event) => {
      console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
      this.$refs.oopsModal.open(event);
    });
  },
};
</script>
