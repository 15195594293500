export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SupportModalForm",
        translations: 


































































































































{
  "title": "Customer support",
  "button": {
    "send": "Send",
    "cancel": "Cancel"
  },
  "labels": {
    "kind": "Kind",
    "object": "Object",
    "message": "Message",
    "email": "Email"
  },
  "requestKind": {
    "SA": "Simple anomaly",
    "BA": "Blocking anomaly",
    "MC": "Major crisis",
    "E": "Evolution",
    "F": "False positive"
  },
  "errors": {
    "kindRequired": "The request kind is required",
    "objectRequired": "The object is required",
    "messageRequired": "The message is required",
    "emailRequired": "The email is required",
    "generique": "Oops, an error occured. We are doing our best to remedy this as quickly as possible."
  },
  "emailSend": {
    "title": "Email sent",
    "description": "A mail has been sent to Flair support team."
  }
}

      }
    }