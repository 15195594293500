import Interceptor from './interceptor';

class ProviderInjector extends Interceptor {
  constructor({ client }) {
    super({ client });
    this.provider = null;
  }

  onBeforeRequest(params) {
    this.provider = this.client.getStorage().get('provider') || null;
    if (!this.provider) {
      return Promise.resolve(params);
    }
    params.headers = params.headers || {};
    params.headers['X-Flair-DataProvider'] = this.provider;
    return Promise.resolve(params);
  }
}

export default ProviderInjector;
