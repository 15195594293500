import RiskInfo from '@/components/risk/RiskInfo';

export default {
  methods: {
    openRisk: function(entity, risk) {
      let activeNav = null;
      switch (risk) {
      case 'financial':
        return this.$router.push({
          name: 'company-details-screen',
          params: { id: entity.id, tab: risk },
        });
      case 'pep':
      case 'adverse_media':
      case 'sanction':
        activeNav = 'analysis';
        break;
      case 'geographical':
        activeNav = 'company';
        break;
      }
      const params = {
        name: risk,
        activeNav: activeNav,
        entity: entity,
      };
      RiskInfo.open(params);
    },
  },
};
