<script>
import { commentNode } from './utilities';

export default {
  inserted(el, binding, vnode) {
    // For robustness purpose... if things don't work properly we
    // assume the user cannot see the component on which the directive
    // is being used
    const dueDiligenceAction = binding.arg.split(':')[0];
    const elementAction = binding.modifiers;

    try {
      const hasRights = vnode.context.$flairClient.DueDiligenceService.userCan(dueDiligenceAction, vnode.context.dueDiligence);

      if (!hasRights) {
        if (elementAction['disable']) {
          el.classList.add('disabled');
          return;
        }
        commentNode(el, vnode);
        return;
      }
    } catch (e) {
      console.warn(`Error on v-feature:${dueDiligenceAction}`, e);
      commentNode(el, vnode);
    }
  },
};
</script>
