<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-confirmation">
      <h6>{{ modalTitle }}</h6>
      <p>{{ modalSubtitle }}</p>
      <p>{{ modalExtraInformation }}</p>
      <div class="buttons">
        <button
          type="button"
          name="button"
          class="vadis-form-button-dangerous-small"
          @click="confirm()"
        >
          {{ confirmButtonLabel }}
        </button>
        <button
          type="button"
          name="button"
          class="vadis-form-button-standard-small"
          @click="cancel()"
        >
          {{ cancelButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.ConfirmationModal">
{
  "cancelLabel": "Cancel",
  "confirmLabel": "Confirm"
}
</i18n>

<script>
// The instance of our Singleton component
let _instance;

export default {
  name: 'ConfirmationModal',
  data() {
    return {
      isOpen: false,
      params: null,
    };
  },
  computed: {
    modalTitle() {
      return this.params.title;
    },
    modalSubtitle() {
      return this.params.subtitle;
    },
    modalExtraInformation() {
      return this.params.information;
    },
    cancelButtonLabel() {
      return this.params.cancelLabel || this.ts.cancelLabel;
    },
    confirmButtonLabel() {
      return this.params.confirmLabel || this.ts.confirmLabel;
    },
  },
  created() {
    if (_instance) {
      throw new Error('ConfirmationModal is not supposed to be included more than once');
    }
    _instance = this;
  },
  destroyed() {
    _instance = null;
  },
  /** PUBLIC API */
  open(params) {
    return _instance.open(params);
  },
  close() {
    return _instance.close();
  },
  methods: {
    confirm() {
      if (this.params.onConfirm) {
        this.params.onConfirm(this);
      }
    },
    cancel() {
      if (this.params.onCancel) {
        this.params.onCancel(this);
      } else {
        this.close();
      }
    },
    open(params) {
      this.params = params;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
