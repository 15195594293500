export default function (Component) {
      Component.options._i18n = {
        namespace: "components.GeographicalRiskInfo",
        translations: 





























{
  "back": "back",
  "riskNames": [
    "Low Risk",
    "Low Risk",
    "Low Risk",
    "Low Risk",
    "Medium Risk",
    "Medium Risk",
    "Medium Risk",
    "High Risk",
    "High Risk",
    "High Risk"
  ]
}

      }
    }