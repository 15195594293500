const roles = {
  manager: 'Manager',
  analyst: 'Analyst',
};

export default {
  makeDecision: {
    ruleChecker(context) {
      if (context.roles.includes(roles.manager)) {
        return true;
      } else if (context.roles.includes(roles.analyst)) {
        return !(context.dueDiligence.computedRisk > 6);
      } else {
        return false;
      }
    },
  },
  writeComment: {
    ruleChecker(context) {
      return true;
    },
  },
  reviseRisk: {
    ruleChecker(context) {
      return (context.roles.includes(roles.manager) || context.roles.includes(roles.analyst));
    },
  },
  notifyManager: {
    ruleChecker(context) {
      if (context.roles.includes(roles.manager)) {
        return false;
      } else if (context.roles.includes(roles.analyst)) {
        return (context.dueDiligence.computedRisk > 6);
      } else {
        return false;
      }
    },
  },
  notifyAnalyst: {
    ruleChecker(context) {
      return !(context.roles.includes(roles.manager) || context.roles.includes(roles.analyst));
    },
  },
  requestInfo: {
    ruleChecker(context) {
      return true;
    },
  },
};
