export default function (Component) {
      Component.options._i18n = {
        namespace: "components.SupportButton",
        translations: 














{
  "support": "support"
}

      }
    }