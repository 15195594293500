export default function (Component) {
      Component.options._i18n = {
        namespace: "components.LoginForm",
        translations: 






















































































































{
  "greeting": "Please login",
  "errors": {
    "wrongUserOrPassword": "Wrong user name or password",
    "usernameRequired": "The username is required",
    "passwordRequired": "The password is required"
  },
  "apiErrorCodes": {
    "invalid_credentials": "Wrong user name or password"
  },
  "buttons": {
    "login": "Login"
  },
  "placeholders": {
    "username": "Username",
    "password": "Password"
  },
  "passwordForgotten": "Password forgotten ?",
  "or": "or",
  "oneChoice": "Your employer has required you to login with {name}",
  "multipleChoices": "Your employer has required you to login with one of this"
}

      }
    }