import _ from 'lodash';
import Service from './service';

export class CompanyPDFReport {
  constructor(data) {
    Object.assign(this, data);
  }
}

export default class PdfReportService extends Service {

  getCompanyPdfData(id, year) {
    const { EntitiesService, TransparencyService } = this.client;
    const data = {};

    const fetchGeographicalRisk = () => {
      return EntitiesService.getCompanyRiskById({
        id: id,
        risk: 'geographical',
      }).promise
        .then((geographicalRisk) => {
          data.geographicalRisk = geographicalRisk;
        });
    };

    const fetchComplianceIndicatorRisk = (which) => {
      return EntitiesService.getCompanyHistoricalRiskIndicatorById(which)
        .promise
        .then((indicator) => {
          return indicator.ensureRelationships({ mediaLinks: true }).then(() => {
            return indicator;
          });
        });
    };

    const fetchPepRisk = () => {
      return fetchComplianceIndicatorRisk({
        id: id,
        year: year,
        risk: 'pep',
      }).then((indicator) => {
        data.pepRisk = indicator;
      });
    };

    const fetchSanctionRisk = () => {
      return fetchComplianceIndicatorRisk({
        id: id,
        year: year,
        risk: 'sanction',
      }).then((indicator) => {
        data.sanctionRisk = indicator;
      });
    };

    const fetchAdverseMediaRisk = () => {
      return fetchComplianceIndicatorRisk({
        id: id,
        year: year,
        risk: 'adverse_media',
      }).then((indicator) => {
        data.adverseMediaRisk = indicator;
      });
    };

    const fetchDetailGroup = () => {
      if (!data.company.primaryGroup) {
        return Promise.resolve();
      }
      return EntitiesService.getGroupById({
        id: data.company.primaryGroup.id,
      }).promise
        .then((res) => {
          data.presentCountries = _.map(res.geographicalPresence, 'country');
          data.group = res;
        });
    };

    // requires fetchDetailGroup to be called before
    const fetchCountriesScore = () => {
      return TransparencyService.getGeographicalRisk().promise
        .then(res => {
          const c = res.data.values;
          data.countries = _.reduce(data.presentCountries, (o, acc) => {
            o[acc] = c[acc];
            return o;
          }, {});
        });
    };

    data.company = EntitiesService.getCompanyById({ id });
    return Promise.all([
      fetchGeographicalRisk(),
      fetchPepRisk(),
      fetchSanctionRisk(),
      fetchAdverseMediaRisk(),
      fetchDetailGroup(),
    ]).then(() => {
      return fetchCountriesScore();
    }).then(() => {
      return new CompanyPDFReport(data);
    });
  }

}
