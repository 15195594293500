<template>
  <div class="vadis-organism-geographical-risk">
    <Loader
      v-if="ready === false"
      :global="false"
      :shown="true"
    />
    <div
      v-else
      class="header"
    >
      <span
        class="company-name-info"
      >
        {{ ts.subtitle }}
        <strong>  {{ company.name }}</strong>
        <span class="tooltip-company">
          <div class="vadis-molecule-tooltip">
            <span class="tooltiptext">
              {{ company.name }}
            </span>
          </div>
          <strong>  {{ company.name }}</strong>
        </span>
      </span>
      <span v-if="lastUpdate">
        {{ ts.lastUpdate }} {{ lastUpdate | humanDate }} <a
          target="_blank"
          href="https://www.transparency.org/"
        >{{ ts.source }}</a>
      </span>
    </div>
    <div class="content">
      <div class="left">
        <GeographicalRiskInfo
          v-if="ready"
          class="info"
          :risk-indicator="riskIndicator"
          @close="countryCode = null"
        />
      </div>
      <GeographicalMap
        v-if="ready"
        :risks="riskIndicator.values"
        :current="currentCountry"
      />
    </div>
    <div class="vadis-molecule-legend-geographical">
      <div class="part">
        <Legend />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.GeographicalRisk">
{
  "subtitle": "You are viewing company level of ",
  "lastUpdate": "Last update :",
  "source": "Source"
}
</i18n>

<script>
import { Company } from 'flair-client';
import GeographicalMap from '@/components/geographical/GeographicalMap';
import GeographicalRiskInfo from '@/components/geographical/GeographicalRiskInfo';
import Loader from '@/components/Loader';
import Legend from '@/components/Legend';

export default {
  name: 'GeographicalRisk',
  components: {
    GeographicalMap,
    GeographicalRiskInfo,
    Legend,
    Loader,
  },
  props: {
    company: {
      type: Company,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    ready: false,
    riskIndicator: null,
    currentCountry: null,
  }),
  computed: {
    lastUpdate: function() {
      if (this.riskIndicator) {
        return this.riskIndicator.lastUpdate;
      }
      return null;
    },
  },
  mounted() {
    this.fetchRiskIndicator();
    this.currentCountry = this.company.address.country;
  },
  methods: {
    setCurrentCountry(value) {
      this.countryCode = value;
    },
    fetchRiskIndicator: function() {
      this.$flairClient.EntitiesService.getCompanyRiskById({
        id: this.company.id,
        risk: 'geographical',
      }).promise
        .then((history) => {
          this.riskIndicator = history;
          this.riskIndicator.history = this.riskIndicator.getRiskHistory();
          this.ready = true;
        });
    },
  },
};

</script>
