export default function (Component) {
      Component.options._i18n = {
        namespace: "components.GroupGeographicalRisk",
        translations: 
































































































{
  "subtitle": "You are viewing group level of ",
  "lastUpdate": "Last update :",
  "source": "Source",
  "companyInCountry": "Companies in {q}",
  "countries": "Countries"
}

      }
    }