import _ from 'lodash';
import { Entity } from '../jsonapi';
import RiskAnalysis from './riskAnalysis';

export default class RiskIndicator extends Entity {

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.details.analysis': RiskAnalysis,
    }));
  }

  getRiskHistory() {
    const arr = _.filter(this.attributes.history, r => r.score !== null);
    return _.reverse(_.clone(arr));
  }

}

RiskIndicator.Template = {
  name: '',
  description: '',
  displayAsNumber: true,
  elements: [],
};
