export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskInfoNav",
        translations: 
















{
  "pep": "Politically exposed person",
  "analysis": "Analysis",
  "adverse_media": "Adverse media",
  "sanction": "Sanctions",
  "geographical":  "Geographical risk",
  "company": "Company",
  "group": "Group",
  "geographicalWithoutGroup": "Geographical risk"
}

      }
    }