import capitalize from './capitalize';
import companyRisk from './companyRisk';
import companySize from './companySize';
import companyType from './companyType';
import companyTypesBvD from './companyTypesBvD';
import dates from './dates';
import money from './money';
import nace2Code from './nace2code';
import percent from './percent';
import personFunction from './personFunction';
import precision from './precision';
import providerRiskIndicator from './providerRiskIndicator';
import truncate from './truncate';
import unknown from './unknown';
import yearOfPseudoDate from './yearOfPseudoDate';

const filters = [
  capitalize,
  companyRisk,
  companySize,
  companyType,
  companyTypesBvD,
  dates,
  money,
  nace2Code,
  percent,
  personFunction,
  precision,
  providerRiskIndicator,
  truncate,
  unknown,
  yearOfPseudoDate,
];

const installFilters = (Vue, flairClient) => {
  filters.forEach(f => {
    f(Vue, flairClient);
  });
};

export { installFilters };
