<template>
  <span
    v-if="country"
    class="vadis-molecule-country"
  >
    <span v-if="includeName && flagAfter">
      {{ countryName }}
    </span>
    <span
      v-if="includeFlag"
      :class="classes"
    />
    <span v-if="includeName && flagBefore">
      {{ countryName }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'CountryFlag',
  props: {
    flagOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    countryOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    flagAfter: {
      type: Boolean,
      default: false,
      required: false,
    },
    country: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    countries() {
      return this.$flairClient.ts('countries');
    },
    countryName() {
      return this.countries[this.country] || `? (${this.country})`;
    },
    flagBefore() {
      return !this.flagAfter;
    },
    includeName() {
      return !this.flagOnly;
    },
    includeFlag() {
      return !this.countryOnly;
    },
    classes() {
      return `flag-icon flag-icon-${this.country.toLowerCase()}`;
    },
  },
};
</script>
