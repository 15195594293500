import Service from './service';
import { Collection } from '../jsonapi';
import { transformToMap } from '../utils/transform';
import { helper } from './helper';
import emit from '../decorators/emit';

export default class MyService extends Service {

  ApiService = this.client.api.MyService;
  ApiEntitiesService = this.client.api.EntitiesService;

  addRecentlyBrowsedCompanies = helper(this.ApiService.pushToBrowseHistory);

  @emit('browseHistory:removed')
  removeFromBrowseHistory = helper(this.ApiService.removeFromBrowseHistory);

  getRecentlyBrowsedCompanies = helper(this.ApiService.browseHistoryCompanies, Collection, (res) => {
    const data = res.data.map((r) => {
      r.attributes.entity.attributes.activity = transformToMap(r.attributes.entity.attributes.activity, 'name', 'value');
      return r.attributes.entity;
    });
    const links = Object.assign({}, res.links, {
      self: '/v1/entities/companies/',
    });
    return { data: data, links: links, meta: res.meta };
  }, this.ApiEntitiesService.searchCompanies);

  addRecentlyBrowsedPeople(person) {
    // Todo refactor the contract of this service
    if (!person.id) {
      return new Collection([]);
    }
    const payload = {
      type: 'BrowseHistory',
      attributes: {
        entity: {
          type: 'Person',
          id: person.id,
        },
      },
    };
    return helper(this.ApiService.pushToBrowseHistoryPeople)(payload);
  }

  getRecentlyBrowsedPeople = helper(this.ApiService.browseHistoryPeople, Collection, (res) => {
    const data = res.data.map((r) => {
      return r.attributes.entity;
    });
    const links = Object.assign({}, res.links, {
      self: '/v1/entities/companies/',
    });
    return { data: data, links: links, meta: res.meta };
  }, this.ApiEntitiesService.searchPeople);

  getProfile = helper(this.ApiService.profile);

  editProfile = helper(this.ApiService.editProfile);

  changePassword = helper(this.ApiService.changePassword);

  getMyDueDiligences = helper(this.ApiService.getMyDueDiligences, Collection);

  getMyTeamMembers = helper(this.ApiService.getMyTeamMembers, Collection);

  getMyTeams = helper(this.ApiService.getMyTeams, Collection);

  getTeamStatisticsByIndicator = helper(this.ApiService.getTeamStatisticsByIndicator);

  getTeamPortfolioGeographicalPresence = helper(this.ApiService.getTeamPortfolioGeographicalPresence);

  getTeamPortfolioGeographicalCountryPresence = helper(this.ApiService.getTeamPortfolioGeographicalCountryPresence, Collection);
}
