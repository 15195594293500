import Service from './service';
import { Collection } from '../jsonapi';
import { Survey, SurveySubmission, SurveyTranslation } from '../entities';
import { helper } from './helper';
import emit from '../decorators/emit';
import Downloadable from '../utils/downloadable';

export default class SurveyService extends Service {

  SurveyApis = this.client.api.SurveysService;
  DueDiligenceApis = this.client.api.DueDiligenceService;

  getSurveys = helper(this.SurveyApis.getSurveys, Collection);

  getSurveyAsPDF(args, filename) {
    return this.SurveyApis.getSurvey(args, { 'Accept': 'application/pdf' }, 'blob')
      .then(res => {
        const document = new Downloadable(res, `${filename}.pdf`);
        return document.download();
      });
  }

  getSurveyTranslation = helper(this.SurveyApis.getSurveyTranslation, SurveyTranslation);

  getSurveyTranslationAsPDF(args, filename) {
    return this.SurveyApis.getSurveyTranslation(args, { 'Accept': 'application/pdf' }, 'blob')
      .then(res => {
        const document = new Downloadable(res, `${filename}.pdf`);
        return document.download();
      });
  }

  getSurveySubmissionBySecretKey = helper(this.DueDiligenceApis.getSurveySubmissionBySecretKey, SurveySubmission);

  getSurveySubmissionById = helper(this.DueDiligenceApis.getSurveySubmissionById, SurveySubmission);

  getSurveySubmissionAsPDF(args, filename) {
    return this.DueDiligenceApis.getSurveySubmissionById(args, { 'Accept': 'application/pdf' },'blob')
      .then(res => {
        const document = new Downloadable(res, `${filename}.pdf`);
        return document.download();
      });

  }

  @emit('survey:created')
  createSurvey = helper(this.SurveyApis.createSurvey, Survey)

  @emit('survey:updated')
  updateSurvey = helper(this.SurveyApis.updateSurvey, Survey)

  @emit('survey:removed')
  removeSurvey = helper(this.SurveyApis.removeSurvey, Survey)

  @emit('surveyTranslation:created')
  addSurveyTranslation = helper(this.SurveyApis.addSurveyTranslation, SurveyTranslation);

  @emit('surveyTranslation:updated')
  updateSurveyTranslation = helper(this.SurveyApis.updateSurveyTranslation, SurveyTranslation);

  @emit('surveyTranslation:removed')
  removeSurveyTranslation = helper(this.SurveyApis.removeSurveyTranslation, SurveyTranslation);

  @emit('surveySubmission:updated')
  updateSurveySubmission = helper(this.DueDiligenceApis.updateSurveySubmission, SurveySubmission);
}

