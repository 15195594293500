<template>
  <div class="leaflet-map" />
</template>

<script>
import json from '../../../public/locales/map/map.json';
import coordinateCenter from '../../../public/locales/map/coordinate.json';

const geoCoordEuropeCenter = [46.85, 2.3518];

export default {
  name: 'GeographicalMap',
  props: {
    risks: {
      type: Object,
      default: null,
      required: true,
    },
    current: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    map: null,
    myjson: json,
    coordinateCenter,
    geoCoordEuropeCenter,
    Leaflet: null,
  }),
  watch: {
    current() {
      this.map.remove();
      this.init();
    },
  },
  created() {
    // We load the leaflet library here instead of importing it in a more classical way
    // because Leaflet requires to be running in a browser (it decorates the window object)
    // By loading the library only when one LeafletMap component is created we ensure that
    // parent components can import this one without crashing because of the window variable
    // Parent components should implement the necessary logic to avoid creating an instance if not
    // running in a browser environment (SSR for instance)
    this.Leaflet = require('leaflet');
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const style = (feature) => {
        let name = 'map_path';
        if (this.risks[feature.properties.iso_a2]) {
          name += ` country_risk country_risk_${this.risks[feature.properties.iso_a2]}`;
        }
        if (feature.properties.iso_a2 === this.current) {
          name += ' current';
        }
        return {
          className: name,
        };
      };

      // default coord
      let coord;
      if (this.current) {
        coord = this.coordinateCenter[this.current];
      } else {
        coord = geoCoordEuropeCenter;
      }
      this.map = this.Leaflet.map(this.$el, { attributionControl: false }).setView(coord, 4);
      const vm = this;

      this.Leaflet.geoJSON(this.myjson, {
        style: style,
        onEachFeature: function(feature, layer) {
          layer.on('click', () => {
            vm.$emit('click', feature.properties.iso_a2);
          });
        },
      }).addTo(this.map);

      // show the scale bar on the lower right corner
      this.Leaflet.control.scale({ position: 'bottomright' }).addTo(this.map);
    },
  },
};

</script>
