export default function (Component) {
      Component.options._i18n = {
        namespace: "components.MediaLinkList",
        translations: 














































{
  "th": {
    "mediaLinks": "Media Links",
    "publicationDate": "Publication Date"
  },
  "published": "Published",
  "noInformation": "No information available."
}

      }
    }