export default class SimpleStorage {
  constructor() {
    this.storage = {};
  }
  get(key, def = undefined) {
    return this.storage[key] || def;
  }
  set(key, value) {
    this.storage[key] = value;
    return this;
  }
}
