<template>
  <div class="vadis-molecule-more-info-title">
    <p>{{ ts[newName] }}</p>
    <SwitcherBar
      :items="tabs[newName]"
      :i18n="ts"
      :active-item="activeNav"
      @click="$emit('click', $event.code)"
    />
    <i
      class="icon-Close"
      @click="$emit('close')"
    />
  </div>
</template>

<i18n namespace="components.RiskInfoNav">
{
  "pep": "Politically exposed person",
  "analysis": "Analysis",
  "adverse_media": "Adverse media",
  "sanction": "Sanctions",
  "geographical":  "Geographical risk",
  "company": "Company",
  "group": "Group",
  "geographicalWithoutGroup": "Geographical risk"
}
</i18n>

<script>
import SwitcherBar from '@/components/SwitcherBar';

const tabs = {
  pep : [
    { code: 'analysis' },
  ],
  'adverse_media' : [
    { code: 'analysis' },
  ],
  'sanction' : [
    { code: 'analysis' },
  ],
  geographical: [
    { code: 'company' },
    { code: 'group',
      features: 'geographicalGroup',
    },
  ],
  geographicalWithoutGroup: [{ code: 'company' }],
};

export default {
  name: 'RiskInfoNav',
  components: {
    SwitcherBar,
  },
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    activeNav: {
      type: String,
      default: null,
      required: true,
    },
    group: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data: () => ({
    tabs: tabs,
  }),
  computed: {
    newName: function() {
      if (this.group && this.name === 'geographical') {
        return 'geographicalWithoutGroup';
      } else {
        return this.name;
      }
    },
  },
};

</script>
