import Service from './service';
import { Entity } from '../jsonapi';
import { RiskIndicator } from '../entities';
import { transformToMap } from '../utils/transform';
import { helper } from './helper';

export default class TransparencyService extends Service {

  ApiService = this.client.api.TransparencyService;

  getGeographicalRisk = helper(this.ApiService.getGeographicalRisk, Entity, (res) => {
    return Object.assign({}, res, {
      data: {
        meta: res.data[0].attributes.lastUpdate,
        values: transformToMap(res.data, 'attributes.country', 'attributes.score'),
      },

    });
  });

  getGeographicalRiskHistory = helper(this.ApiService.getGeographicalRiskHistory, RiskIndicator);

}
