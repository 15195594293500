import { Entity } from '../jsonapi';
import Document from './document';
import Member from './member';
import Survey from './survey';

export default class Timeline extends Entity {

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.members': Member,
      'attributes.documents': Document,
      'attributes.surveys': Survey,
    }));
  }

}
