import { Entity } from '../jsonapi';
import UserProfile, { UNKNOWN_USER_PROFILE } from './user-profile';

export default class Member extends Entity {

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.user': UserProfile,
    }));
  }

  get initials() {
    return this.attributes.user.initials;
  }

  get fullName() {
    return this.attributes.user.fullName;
  }

}

const UNKNOWN_MEMBER = Entity.factor({
  data: {
    id: null,
    type: 'DueDiligence.Member',
    attributes: {
      role: 'Unknown',
      user: UNKNOWN_USER_PROFILE,
    },
  },
}, Member);

export { UNKNOWN_MEMBER };
