<template>
  <div>
    <div class="vadis-molecule-risk-analysis-details">
      <div class="top">
        <div class="left">
          <div>
            <h6>
              <analysis-source-info :analysis="analysis" />
            </h6>
            <p v-html="analysis.summary" />
          </div>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(detail, i) in details"
              :key="i"
            >
              <p class="label">
                {{ ts[detail.label] }}
              </p>
              <p>{{ itemValue(detail) }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <MediaLinkList :media-links="analysis.mediaLinks" />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.RiskAnalysisDetails">
{
  "subjectName": "Name",
  "subjectPosition": "Position(s)",
  "publishedAt": "Date"
}
</i18n>

<script>
import _ from 'lodash';
import MediaLinkList from '@/components/risk/MediaLinkList';
import AnalysisSourceInfo from '@/components/AnalysisSourceInfo';

const details = [
  {
    label: 'subjectName',
    path: 'subjectName',
    filter: 'unknown',
  }, {
    label: 'subjectPosition',
    path: 'subjectPosition',
    filter: 'unknown',
  }, {
    label: 'publishedAt',
    path: 'publishedAt',
    filter: 'humanDate',
  },
];

export default {
  name: 'RiskAnalysisDetails',
  components: {
    MediaLinkList,
    AnalysisSourceInfo,
  },
  props: {
    analysis: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    details: details,
  }),
  methods: {
    itemValue(item) {
      const value = _.get(this.analysis, item.path);
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      if (item.filter) {
        return this.$options.filters[item.filter](value);
      }
      return value;
    },
  },
};

</script>
