import { DueDiligenceAction } from '@/directives';
import { i18nTsMixin, eventsMixin, openRiskMixin, openConfirmationModal, injectFlairClient } from '@/mixins';

import FlairClient from 'flair-client';
import { installFilters } from './utils/filters';

class FlairVuePlugin {

  #flairClient;
  #featureFlags;
  constructor({ flairClient, featureFlags }) {
    if (!(flairClient instanceof FlairClient)) {
      throw new Error('FlairClient instance expected');
    }
    this.#flairClient = flairClient;
    this.#featureFlags = featureFlags;
  }

  install(Vue) {
    /** Directives */
    Vue.directive('due-diligence-action', DueDiligenceAction);

    /** Global mixin applied to all components */
    Vue.mixin(injectFlairClient);
    Vue.mixin(i18nTsMixin);
    Vue.mixin(eventsMixin);
    Vue.mixin(openRiskMixin);
    Vue.mixin(openConfirmationModal);

    /** Install global filters */
    installFilters(Vue, this.#flairClient);

    /** Decorate Vue with global helpers */
    Vue.prototype.$featureEnabled = (feature) => {
      return this.#featureFlags.isEnabled(feature);
    };
  }
}

export default FlairVuePlugin;
