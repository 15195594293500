<template>
  <LoginForm
    :error="error"
    :login-information="loginInformation"
  />
</template>

<style lang="scss">
#login-screen {
  background: url(../assets/login-screen-background.jpg);
  .topbar {
    display: none;
  }
}
</style>

<script>
import LoginForm from '@/components/LoginForm';
export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  data: () => ({
    error: null,
    loginInformation: '',
  }),
  beforeRouteEnter: function(to, from, next) {
    next(vm => {
      if (to.query.err) {
        vm.error = to.query.err;
      } else if (to.query.loginInformation) {
        vm.loginInformation = to.query.loginInformation;
      } else if (vm.$flairClient.AuthenticationService.isLoggedIn()) {
        vm.$router.push('/');
      }
    });
  },
};
</script>
