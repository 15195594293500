import Service from './service';
import { DueDiligence, Attachment } from '../entities';
import Collection from '../jsonapi/collection';
import { helper } from './helper';
import emit from '../decorators/emit';
import configRules from '../config/dueDiligenceRulesChecker';
import Downloadable from '../utils/downloadable';
import _ from 'lodash';

export default class DueDiligenceService extends Service {

  ApiEntitiesService = this.client.api.EntitiesService;
  ApiDueDiligenceServiceService = this.client.api.DueDiligenceService;

  getDueDiligenceById = helper(this.ApiDueDiligenceServiceService.getDueDiligenceById, DueDiligence);

  getCompanyLastDueDiligence = helper(this.ApiEntitiesService.getCompanyLastDueDiligence, DueDiligence);

  userCan(action, dd) {
    const userRoles = _.map(_.filter(dd.members, (e) => (e.user.id === this.client.AuthenticationService.me.id)), e => e.role);

    if (userRoles.length === 0) {
      return false;
    }

    const exists = (_.keys(configRules)).includes(action);

    if (!exists) {
      throw new Error(`Error on v-feature:${action}, unknown action`);
    }

    return configRules[action].ruleChecker({ dueDiligence: dd, roles: userRoles });
  }

  getDueDiligenceAsPDF(args, filename) {
    return this.ApiDueDiligenceServiceService.getDueDiligenceById(args, { 'Accept': 'application/pdf' },'blob')
      .then(res => {
        const document = new Downloadable(res, `${filename}.pdf`);
        return document.download();
      });
  }

  getDueDiligenceRoles = helper(this.ApiDueDiligenceServiceService.getDueDiligenceRoles, Collection);

  @emit('member:invited')
  inviteMembers = helper(this.ApiDueDiligenceServiceService.inviteMembers, DueDiligence);

  @emit('dueDiligence:started')
  startDueDiligence = helper(this.ApiEntitiesService.startDueDiligence, DueDiligence);

  @emit('attachment:added')
  addAttachments = helper(this.ApiDueDiligenceServiceService.addAttachments, Attachment);

  @emit('attachment:removed')
  removeAttachments = helper(this.ApiDueDiligenceServiceService.removeAttachments, Attachment)

  @emit('comment:posted')
  postComment = helper(this.ApiDueDiligenceServiceService.postComment, DueDiligence);

  @emit('risk:revised')
  reviseRisk = helper(this.ApiDueDiligenceServiceService.reviseRisk, DueDiligence);

  @emit('decision:made')
  makeDecision = helper(this.ApiDueDiligenceServiceService.makeDecision, DueDiligence);

  @emit('info:requested')
  requestInfo = helper(this.ApiDueDiligenceServiceService.requestInfo, DueDiligence);
}

