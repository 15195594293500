export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ConfirmationModal",
        translations: 





























{
  "cancelLabel": "Cancel",
  "confirmLabel": "Confirm"
}

      }
    }