import Service from './service';
import { Collection } from '../jsonapi';
import { helper } from './helper';
import emit from '../decorators/emit';

export default class PortfolioService extends Service {

  ApiService = this.client.api.MyService;7
  RiskService = this.client.api.RiskService;

  getTeamPortfolioSubjects = helper(this.ApiService.getTeamPortfolioSubjects, Collection);

  @emit('subject:added')
  addTeamPortfolioSubject(id, subject) {
    return this.ApiService.addTeamPortfolioSubject({
      id: id,
      type: 'Portfolio.Subject',
      attributes: {
        entity: {
          type: 'Company',
          id: subject.id,
        },
      },
    }).then(() => {
      return { id: subject.id };
    });
  }

  @emit('subject:removed')
  deleteTeamPortfolioSubject(subject) {
    return this.ApiService.deleteTeamPortfolioSubject(subject)
      .then(() => {
        return subject;
      });
  }

  getRiskTreeCompany = helper(this.RiskService.getRiskTreeCompany);
}
