<template>
  <div
    v-if="!hasCurrentAnalysis()"
    class="vadis-molecule-risk-analysis-list-container"
  >
    <Loader
      v-if="risk === null"
      :global="false"
      :shown="true"
    />
    <div
      v-else
      class="header"
    >
      <div class="left">
        <span>
          {{ ts.header.description({ number: risk.details.analysis.length }) }} <strong>{{ entity.name }}.</strong>
        </span>
        &nbsp;
      </div>
      <div class="right">
        <span
          v-if="risk.lastUpdate"
          class="vadis-body-text-disabled"
        >
          {{ ts.header.lastUpdate }} {{ risk.lastUpdate | humanDate }}
        </span>
        &nbsp;
      </div>
    </div>
    <div
      v-if="risk"
      class="body"
    >
      <ul class="vadis-molecule-risk-analysis-list">
        <li
          v-for="(analysis, i) in orderedAnalysis"
          :key="i"
          @click="setCurrentAnalysis(analysis)"
        >
          <div class="vadis-molecule-risk-analysis-card">
            <div class="card">
              <div class="copy">
                <p>
                  {{ ts.publishedAt({ publishedAt: humanPublishedAt(analysis) }) }}
                </p>
                <p class="description">
                  <analysis-source-info :analysis="analysis" />
                </p>
                <p class="subject">
                  {{ analysis.subjectName }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div class="body">
      <a @click="backToList()">{{ ts.backToList }}</a>
      <RiskAnalysisDetails :analysis="currentAnalysis" />
    </div>
  </div>
</template>

<i18n namespace="components.RiskAnalysisList">
{
  "header": {
    "description": "{number} analysis linked to ",
    "lastUpdate": "Last updated "
  },
  "backToList": "Back to list",
  "publishedAt": "Published {publishedAt}"
}
</i18n>

<script>
import _ from 'lodash';
import Loader from '@/components/Loader';
import RiskAnalysisDetails from '@/components/risk/RiskAnalysisDetails';
import AnalysisSourceInfo from '@/components/AnalysisSourceInfo';

import { Company, Person } from 'flair-client';

export default {
  name: 'RiskAnalysisList',
  components: {
    Loader,
    RiskAnalysisDetails,
    AnalysisSourceInfo,
  },
  props: {
    entity: {
      type: Object,
      default: null,
      required: true,
    },
    name: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    currentAnalysis: null,
    risk: null,
    instance: null,
  }),
  computed: {
    orderedAnalysis() {
      return _.orderBy(this.risk.details.analysis, ['publishedAt'], ['desc']);
    },
  },
  mounted() {
    this.instance = this.$flairClient.AppService.instance;
    if (this.entity instanceof Company) {
      this.fetchRiskIndicatorCompany();
    }
    if (this.entity instanceof Person) {
      this.fetchRiskIndicatorPerson();
    }
  },
  methods: {
    fetchRiskIndicatorCompany() {
      this.$flairClient.EntitiesService.getCompanyHistoricalRiskIndicatorById({
        id: this.entity.id,
        year: this.instance.defaultYear,
        risk: this.name,
      }).promise.then((c) => {
        this.risk = c;
      });
    },
    fetchRiskIndicatorPerson() {
      this.$flairClient.EntitiesService.getPersonHistoricalRiskIndicatorById({
        id: this.entity.id,
        year: this.instance.defaultYear,
        risk: this.name,
      }).promise.then((c) => {
        this.risk = c;
      });
    },
    setCurrentAnalysis(analysis) {
      if (analysis) {
        analysis.load().then(() => {
          this.currentAnalysis = analysis;
        });
      } else {
        this.currentAnalysis = analysis;
      }
    },
    hasCurrentAnalysis() {
      return !!this.currentAnalysis;
    },
    backToList() {
      this.setCurrentAnalysis(null);
    },
    humanPublishedAt(analysis) {
      return this.$options.filters.humanDate(analysis.publishedAt);
    },
  },
};

</script>
