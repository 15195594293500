import { Entity } from '../jsonapi';
import _ from 'lodash';

export default class AppInstance extends Entity {

  supportsCreditSafe() {
    return this.isProvider('CreditSafe');
  }

  supportsFlairData() {
    return this.isProvider('FlairData');
  }

  isProvider(providerName) {
    return (localStorage.getItem('provider') || this.currentProvider) === providerName;
  }

  get backendVersion() {
    return _.get(this, 'document.instance.version');
  }

  get backendVersionShorter() {
    return this.document.instance.version.split('.').splice(0, 4).join('.');
  }

  get dataVersion() {
    return _.get(this, 'document.databases.data.meta.version');
  }

  get environment() {
    return _.get(this, 'document.environment');
  }

  get server() {
    return _.get(this, 'document.databases.data.server');
  }

  get source() {
    return _.get(this, 'document.databases.data.meta.source');
  }

  get sourceDate() {
    return _.get(this, 'document.databases.data.meta.sourceVersion');
  }

  get articlesSource() {
    return _.get(this, 'document.databases.data.meta.articlesSource');
  }

  get articlesSourceDate() {
    return _.get(this, 'document.databases.data.meta.articlesSourceVersion');
  }

  get provider() {
    return _.get(this, 'document.databases.data.meta.provider');
  }

  get defaultYear() {
    return _.get(this, 'document.databases.data.meta.defaultYear') || 2021;
  }

  get providers() {
    return _.get(this, 'document.providers');
  }

  supportsFeature(feature) {
    const providerFeatures = _.get(_.find(this.document.providers, (p) => p.isCurrent), 'disabled');
    return providerFeatures[feature];
  }

  get currentProvider() {
    return _.get(_.find(this.document.providers, p => p.isCurrent), 'name');
  }

  get authMethods() {
    return _.get(this, 'document.authMethods');
  }

  hasInternalAuthentication() {
    return _.find(this.document.authMethods, m => m.type === 'internal');
  }

}
