export default function (Component) {
      Component.options._i18n = {
        namespace: "components.GeographicalRisk",
        translations: 























































{
  "subtitle": "You are viewing company level of ",
  "lastUpdate": "Last update :",
  "source": "Source"
}

      }
    }