/**
 * Global mixin bringing an event subscription mechanism for components.
 *
 * In your component, you can include an 'events' section
 *
 * export default {
 *   ...,
 *   events: {
 *     'authentication:loggedIn': (vm) => {
 *       // This callback will be called every time such an event is triggered
 *       // the `vm` argument is a reference to the component's instance
 *     },
 *     'app:*:*': (vm) => {
 *       // This callback will be called every time such an event is triggered
 *       // the `vm` argument is a reference to the component's instance
 *     }
 *   }
 *
 */
export default {
  mounted() {
    this.$options.handlers = {};
    for (const [key, cb] of Object.entries(this.$options.events || {})) {
      const handler = (payload, event) => {
        cb(this, payload, event);
      };
      this.$options.handlers[key] = handler;
      this.$flairClient.on(key, handler);
    }
  },
  beforeDestroy() {
    for (const [key, handler] of Object.entries(this.$options.handlers || {})) {
      this.$flairClient.unsubscribe(key, handler);
    }
  },
};
