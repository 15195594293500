<template>
  <div v-if="isOpen">
    <div class="vadis-style-global-DialogBg" />
    <div class="vadis-modal-customer-support">
      <div class="title">
        <h6>
          {{ ts.title }}
        </h6>
        <i
          class="icon-Close"
          @click="close()"
        />
      </div>
      <div
        v-if="success"
        class="email-send"
      >
        <div><i class="icon-Check" /></div>
        <h3>{{ ts.emailSend.title }}</h3>
        <p>{{ ts.emailSend.description }}</p>
      </div>
      <p
        v-if="error"
        class="error-messages"
      >
        {{ ts.errors.generique }}
      </p>
      <form
        v-if="!success"
        id="customer-support-form"
        class="vadis-organism-customer-support"
        novalidate
        @submit.prevent="validate"
      >
        <div :class="{ 'form-row': true, invalid: $v.form.object.$error }">
          <label>
            {{ ts.labels.kind }}
          </label>
          <div class="vadis-form-field-select">
            <select v-model="form.kind">
              <template v-for="kind in requestKind">
                <option
                  :key="kind.id"
                  :value="kind.value"
                >
                  <p>
                    {{ ts.requestKind[kind.id] || kind.value }}
                  </p>
                </option>
              </template>
            </select>
            <i class="icon-Dropdown" />
          </div>
          <p
            v-if="$v.form.kind.$error"
            class="validation-error"
          >
            {{ ts.errors.kindRequired }}
          </p>
        </div>
        <div :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.object.$error }">
          <label>
            {{ ts.labels.object }}
          </label>
          <input
            id="object-input"
            v-model.trim="form.object"
            @input="$v.form.object.$touch"
          >
          <p
            v-if="$v.form.object.$error"
            class="validation-error"
          >
            {{ ts.errors.objectRequired }}
          </p>
        </div>
        <div :class="{'form-row vadis-form-field-textarea': true, invalid: $v.form.message.$error }">
          <label>
            {{ ts.labels.message }}
          </label>
          <textarea
            v-model="form.message"
            placeholder="text"
          />
          <p
            v-if="$v.form.message.$error"
            class="validation-error"
          >
            {{ ts.errors.messageRequired }}
          </p>
        </div>
        <div
          :class="{ 'form-row vadis-form-field-standard': true, invalid: $v.form.reply_to.$error}"
          :disabled="hasEmail()"
        >
          <label>
            {{ ts.labels.email }}
          </label>
          <input
            id="object-input"
            v-model.trim="form.reply_to"
            @input="$v.form.reply_to.$touch"
          >
          <p
            v-if="$v.form.reply_to.$error"
            class="validation-error"
          >
            {{ ts.errors.emailRequired }}
          </p>
        </div>
        <div class="form-row buttons">
          <button
            class="vadis-form-button-main"
            type="submit"
          >
            {{ ts.button.send }}
          </button>
          <button
            class="
            vadis-form-button-standard"
            @click="close()"
          >
            {{ ts.button.cancel }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<i18n namespace="components.SupportModalForm">
{
  "title": "Customer support",
  "button": {
    "send": "Send",
    "cancel": "Cancel"
  },
  "labels": {
    "kind": "Kind",
    "object": "Object",
    "message": "Message",
    "email": "Email"
  },
  "requestKind": {
    "SA": "Simple anomaly",
    "BA": "Blocking anomaly",
    "MC": "Major crisis",
    "E": "Evolution",
    "F": "False positive"
  },
  "errors": {
    "kindRequired": "The request kind is required",
    "objectRequired": "The object is required",
    "messageRequired": "The message is required",
    "emailRequired": "The email is required",
    "generique": "Oops, an error occured. We are doing our best to remedy this as quickly as possible."
  },
  "emailSend": {
    "title": "Email sent",
    "description": "A mail has been sent to Flair support team."
  }
}
</i18n>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { version } from '@/constants';

const requestKind = [
  {
    id: 'SA',
    value:'Simple anomaly',
  },
  {
    id: 'BA',
    value:'Blocking anomaly',
  },
  {
    id: 'MC',
    value:'Major crisis',
  },
  {
    id: 'E',
    value:'Evolution',
  },
  {
    id: 'F',
    value: 'False positive',
  },
];
export default {
  name: 'SupportModalForm',
  data: () => ({
    requestKind,
    isOpen: false,
    error: false,
    success: false,
    form: {
      kind: null,
      object: '',
      message: '',
      reply_to: '',
      info: {},
    },
    dragHover: false,
  }),
  validations: {
    form: {
      kind: {
        required,
      },
      object: {
        required,
      },
      message: {
        required,
      },
      reply_to: {
        required,
        email,
      },
    },
  },
  methods: {
    open() {
      this.isOpen = true;
      this.form.reply_to = this.getDefaultReplyTo();
    },
    close() {
      this.clearForm();
      this.isOpen = false;
    },
    sendEmail() {
      this.form.info = {
        version: version,
        language: window.navigator.language,
        location: window.location.href,
        userAgent: window.navigator.userAgent,
      };
      this.$flairClient.SupportService.reportIssue(this.form)
        .promise.then(() => {
          this.success = true;
        }).catch((err) => {
          console.error('#failed', err);
          this.error = true;
          throw err;
        });
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendEmail();
      }
    },
    clearForm() {
      this.$v.$reset();
      this.success = false;
      this.error = false;
      this.form = {
        kind: null,
        object: '',
        message: '',
        reply_to: this.getDefaultReplyTo(),
        info: {},
      };
    },
    getDefaultReplyTo() {
      const me = this.$flairClient.AuthenticationService.me;
      return me ? me.email : null;
    },
    hasEmail() {
      return !!this.getDefaultReplyTo();
    },
  },
};
</script>
