import downloadFile from '../utils/downloadFile';

export default class Downloadable {
  constructor(data, filename) {
    this.data = (data instanceof Blob) ? data : new Blob([data]);
    this.filename = filename;
  }

  download() {
    const url = window.URL.createObjectURL(this.data);
    downloadFile(url, this.filename);
  }
}
