import { Entity } from '../jsonapi';
import _ from 'lodash';

export default class SurveySubmission extends Entity {
  get survey() {
    return _.find(this.included, ['type', 'Survey.Translation']);
  }

  get company() {
    return _.find(this.included, ['type', 'Company']);
  }
}
