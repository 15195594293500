import Vue from 'vue';
import App from './App.vue';
import Vuelidate from 'vuelidate';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import { createFlairClient } from './flair-client';
import loadHighchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge';
import FlairVuePlugin from './plugin';
import { createRouter } from './router';
import { setupFeatureFlags } from './feature-flags';

loadHighchartsMore(Highcharts);
solidGauge(Highcharts);

Vue.use(Vuelidate);
Vue.use(HighchartsVue, {
  highcharts: Highcharts,
});
Vue.config.productionTip = false;

const flairClient = createFlairClient();
const featureFlags = setupFeatureFlags(flairClient);
const router = createRouter({ flairClient, featureFlags });

Vue.use(new FlairVuePlugin({ flairClient, featureFlags }));

const app = new Vue({
  router,
  $flairClient: flairClient,
  featureFlags,
  render: h => h(App),
});

flairClient.initialize()
  .then(() => {
    app.$mount('#app');
  });
