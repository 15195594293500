const version = process.env.VUE_APP_FLAIR_VERSION;
const USER_GUIDE_FILE = 'https://storage.gra.cloud.ovh.net/v1/AUTH_0846aa30376b4afdba283314dffa18ff/flair-media-public/$LANG$_flair_manual.pdf';
const DUE_DILIGENCE_MANUAL = 'https://storage.gra.cloud.ovh.net/v1/AUTH_0846aa30376b4afdba283314dffa18ff/flair-media-public/$LANG$_due_diligence_manual.pdf';

// Temporary default download file in English for languages not yet supported

const DEFAULT_USER_GUIDE_FILE = 'https://storage.gra.cloud.ovh.net/v1/AUTH_0846aa30376b4afdba283314dffa18ff/flair-media-public/EN_flair_manual.pdf';
const DEFAULT_DUE_DILIGENCE_MANUAL = 'https://storage.gra.cloud.ovh.net/v1/AUTH_0846aa30376b4afdba283314dffa18ff/flair-media-public/EN_due_diligence_manual.pdf';

export {
  version,
  USER_GUIDE_FILE,
  DUE_DILIGENCE_MANUAL,
  DEFAULT_USER_GUIDE_FILE,
  DEFAULT_DUE_DILIGENCE_MANUAL,
};
