import Service from './service';
import { Collection } from '../jsonapi';
import { transformToMap } from '../utils/transform';
import { helper } from './helper';

export const SEARCH_MODE_PERSON = 'Person';
export const SEARCH_MODE_COMPANY = 'Company';

export default class SearchService extends Service {
  static SEARCH_MODE_COMPANY = 'Company';
  static SEARCH_MODE_PERSON = 'Person';

  ApiMyService = this.client.api.MyService;

  /**
   * Make a particular search and returns a JsonAPI Collection with
   * the results.
   *
   * When `search` is defined, the collection load is ongoing in silent
   * mode when the collection is returned. Otherwise a Collection
   * is returned but no actual backend call is done.
   *
   * @param search: Search with type=T
   * @return Collection<T>
   */
  search(search) {
    if (search.attributes.mode === 'Company') {
      return this.searchCompanies(search);
    } else if (search.attributes.mode === 'Person') {
      return this.searchPeople(search);
    } else {
      throw new Error('Not implemented');
    }
  }

  /**
   * Specialization of `search` for easier implementation by subclasses.
   *
   * @param search: Search(s | s.attributes.mode === 'Company' )
   * @return Collection<Company>
   */
  searchCompanies(search) {
    const { api } = this.client;
    const loader = (search) => {
      const payload = {
        ...search.attributes,
      };
      if (search.attributes.nopaging) {
        payload.nopaging = '';
      } else if (!payload['page[size]']) {
        payload['page[size]'] = 15;
      }
      return api.EntitiesService.searchCompanies(payload).then(res => {
        return Object.assign({}, res, {
          //TODO: investigate for @llambeau
          data: (res.data || []).map(r => {
            r.attributes.activity = transformToMap(r.attributes.activity, 'name', 'value');
            return r;
          }),
        });
      });
    };
    loader.rebind = (args) => {
      return api.EntitiesService.searchCompanies.rebind(args);
    };
    const c = new Collection(loader);
    if (search && search.attributes && (search.attributes.q || search.attributes.country || search.attributes.group)) {
      //TODO implement a better way for errors
      c.load(search, false);
    }
    return c;
  }

  /**
   * Specialization of `search` for easier implementation by subclasses.
   *
   * @param search: Search(s | s.attributes.mode === 'Person' )
   * @return Collection<Person>
   */
  searchPeople(search) {
    const { api } = this.client;
    const loader = (search) => {
      const payload = {
        ...search.attributes,
      };
      if (!payload['page[size]']) {
        payload['page[size]'] = 15;
      }
      return api.EntitiesService.searchPeople(payload);
    };
    loader.rebind = (args) => {
      return api.EntitiesService.searchPeople.rebind(args);
    };
    const c = new Collection(loader);
    if (search && search.attributes && search.attributes.q) {
      //TODO implement a better way for errors
      c.load(search, false);
    }
    return c;
  }

  /**
   * Returns the current's user search history as a Collection.
   *
   * POST: returned collection should be loaded in silent mode.
   * @param mode: Search.Mode (s | s === 'Person' || s === 'Company' )
   * @return Collection<Search>
   */
  getSearchHistory = helper(this.ApiMyService.searchHistory, Collection);

  /**
   * Push a search history entry into the stack of recent searches.
   *
   * @param search: Search.Create
   * @return a Promise that resolves on success and rejects on failure.
   */
  pushSearchHistory = helper(this.ApiMyService.pushToHistory);

  /** Instance getters for constants */
  get SEARCH_MODE_COMPANY() { return SEARCH_MODE_COMPANY; }
  get SEARCH_MODE_PERSON() { return SEARCH_MODE_PERSON; }
}
