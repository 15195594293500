import AdminService from './admin.service';
import AppService from './app.service';
import AuthenticationService from './authentication.service';
import DataProviderService from './dataprovider.service';
import DownloadService from './download.service';
import DueDiligenceService from './duediligence.service';
import EntitiesService from './entities.service';
import I18NService from './i18n.service';
import MyService from './my.service';
import OpenGraphService from './opengraph.service';
import PdfReportService from './pdf.report.service';
import PortfolioService from './portfolio.service';
import PrintService from './print.service';
import SearchService from './search.service';
import Service from './service';
import SupportService from './support.service';
import SurveyService from './survey.service';
import TransparencyService from './transparency.service';

export {
  AdminService,
  AppService,
  AuthenticationService,
  DataProviderService,
  DownloadService,
  DueDiligenceService,
  EntitiesService,
  I18NService,
  MyService,
  OpenGraphService,
  PdfReportService,
  PortfolioService,
  PrintService,
  SearchService,
  Service,
  SupportService,
  SurveyService,
  TransparencyService,
};

export default {
  createServices: (client) => {
    return {
      AdminService: new AdminService({ client }),
      AppService: new AppService({ client }),
      AuthenticationService: new AuthenticationService({ client }),
      DataProviderService: new DataProviderService({ client }),
      DownloadService: new DownloadService({ client }),
      DueDiligenceService: new DueDiligenceService({ client }),
      EntitiesService: new EntitiesService({ client }),
      I18NService: new I18NService({ client }),
      MyService: new MyService({ client }),
      OpenGraphService: new OpenGraphService({ client }),
      PdfReportService: new PdfReportService({ client }),
      PortfolioService: new PortfolioService({ client }),
      PrintService: new PrintService({ client }),
      SearchService: new SearchService({ client }),
      SupportService: new SupportService({ client }),
      SurveyService: new SurveyService({ client }),
      TransparencyService: new TransparencyService({ client }),
    };
  },
};
