<template>
  <div class="vadis-organism-geographical-risk">
    <div class="header">
      <span
        class="company-name-info"
      >
        {{ ts.subtitle }}
        <strong>  {{ primaryGroup.name }}</strong>
        <span class="tooltip-company">
          <div class="vadis-molecule-tooltip">
            <span class="tooltiptext">
              {{ primaryGroup.name }}
            </span>
          </div>
          <strong>  {{ primaryGroup.name }}</strong>
        </span>
      </span>
      <span>
        <span v-if="lastUpdate">{{ ts.lastUpdate }} {{ lastUpdate | humanDate }} </span> <a
          target="_blank"
          href="https://www.transparency.org/"
        >{{ ts.source }}</a>
      </span>
    </div>
    <div class="content">
      <div class="left">
        <div v-if="historyReady">
          <GeographicalRiskInfo
            class="info"
            :risk-indicator="riskIndicator"
            :back="true"
            @close="closeRiskHistory()"
            @back="closeRiskHistory()"
          />
          <div
            v-if="companies.loaded"
            class="company-group-list"
          >
            <div class="title">
              {{ ts.companyInCountry({ q: currentCountry}) }}
            </div>
            <div
              v-for="(c, i) in companies.items"
              :key="i"
              class="vadis-molecule-detail-item"
            >
              <span class="vadis-molecule-detail-label"><a @click="$emit('click', c);"> {{ c.name }}</a></span>
              <span class="vadis-molecule-detail-value"> {{ c.size | companySize }} </span>
            </div>
          </div>
          <Loader
            v-show="companies.loading"
            class="vadis-molecule-loader"
            :global="false"
            :shown="true"
          />
        </div>
        <div
          v-if="group && !historyReady"
          class="company-group-list"
        >
          <div class="title">
            {{ ts.countries }}
          </div>
          <div
            v-for="(c, i) in group.geographicalPresence"
            :key="i"
            class="vadis-molecule-detail-item"
            @click="fetchDetailsCountry(c.country)"
          >
            <span class="vadis-molecule-detail-label">
              <CountryFlag
                :country="c.country"
                :flag-only="true"
              />
              {{ c.country }}
            </span>
            <span class="vadis-molecule-detail-value"> {{ c.numberOfCompanies }} </span>
          </div>
        </div>
      </div>
      <GeographicalMap
        v-if="ready"
        :risks="countries"
        :current="currentCountry"
        @click="fetchDetailsCountry($event)"
      />
    </div>
    <div class="vadis-molecule-legend-geographical">
      <div class="part">
        <Legend />
      </div>
    </div>
  </div>
</template>

<i18n namespace="components.GroupGeographicalRisk">
{
  "subtitle": "You are viewing group level of ",
  "lastUpdate": "Last update :",
  "source": "Source",
  "companyInCountry": "Companies in {q}",
  "countries": "Countries"
}
</i18n>

<script>
import _ from 'lodash';
import CountryFlag from '@/components/CountryFlag';
import Loader from '@/components/Loader';
import GeographicalMap from '@/components/geographical/GeographicalMap';
import Legend from '@/components/Legend';
import GeographicalRiskInfo from '@/components/geographical/GeographicalRiskInfo';

export default {
  name: 'GroupGeographicalRisk',
  components: {
    Loader,
    GeographicalMap,
    Legend,
    GeographicalRiskInfo,
    CountryFlag,
  },
  props: {
    primaryGroup: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    group: null,
    ready: false,
    countries: {},
    presentCountries: {},
    historyReady: false,
    riskIndicator: null,
    currentCountry: '',
    companies: undefined,
    lastUpdate: undefined,
  }),
  mounted() {
    this.fetchDetailGroup()
      .then(() => {
        this.fetchCountries();
      });
  },
  methods: {
    fetchCountries() {
      return this.$flairClient.TransparencyService.getGeographicalRisk().promise
        .then(res => {
          this.lastUpdate = res.data.meta;
          const c = res.data.values;
          this.countries = _.reduce(this.presentCountries, (o, acc) => { o[acc] = c[acc]; return o; }, {});
          this.ready = true;
        });
    },
    fetchDetailGroup: function() {
      return this.$flairClient.EntitiesService.getGroupById({ id: this.primaryGroup.id }).promise
        .then((res) => {
          this.presentCountries = _.map(res.geographicalPresence, (r) => r.country);
          this.group = res;
        });
    },
    fetchDetailsCountry(country) {
      if (!(_.find(this.presentCountries, (e) => e === country))) {
        return;
      }
      this.currentCountry = country;
      this.getCompaniesInGroupCountry();
      this.$flairClient.TransparencyService.getGeographicalRiskHistory({ country }).promise
        .then(res => {
          this.riskIndicator = res;
          this.riskIndicator.history = this.riskIndicator.getRiskHistory();
          this.historyReady = true;
        });
    },
    getCompaniesInGroupCountry() {
      this.companies = this.$flairClient.EntitiesService.getGroupCompaniesByCountry({
        id: this.primaryGroup.id,
        country: this.currentCountry,
      });
    },
    closeRiskHistory() {
      this.historyReady = false;
      this.currentCountry = '';
    },
  },
};

</script>
