export default (Vue, flairClient) => {
  Vue.filter('yearOfPseudoDate', (date) => {
    if (!date) {
      return '';
    }
    if (typeof (date) === 'number') {
      return date.toString().substring(0, 4);
    } else {
      return date.substring(0, 4);
    }
  });
};
