import { Entity } from '../jsonapi';
import { AppInstance } from '../entities';
import Service from './service';
import emit from '../decorators/emit';

export default class AppService extends Service {

  constructor({ client }) {
    super({ client });
    this._instance = null;

    client.on('authentication:loggedIn', () => {
      this.getInstanceInfo(true);
    });
  }

  initialize() {
    return this.getInstanceInfo();
  }

  get instance() {
    return this._instance;
  }

  @emit('instance:updated')
  getInstanceInfo(force = false) {
    if (this._instance && !force) {
      return Promise.resolve(this._instance);
    }
    return this.client.api.AppService.getInstance()
      .then((res) => {
        this._instance = Entity.factor(res, null, AppInstance);
        return this._instance;
      });
  }

}
