export default (Vue, flairClient) => {
  Vue.filter('companyType', (type) => {
    if (!type) {
      return '';
    }
    if (flairClient.AppService.instance.supportsCreditSafe()) {
      return flairClient.ts('companyTypes')[type] || type;
    } else {
      return flairClient.ts('companyTypesBvD')[type] || type;
    }
  });
};
