export default (Vue, flairClient) => {
  Vue.filter('euros', (value) => {
    if (!value) {
      return '-';
    }
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    });
    return formatter.format(value);
  });

  Vue.filter('keuros', (value) => {
    if (!value) {
      return '-';
    }
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
    });
    return `${formatter.format(value)} k€`;
  });

  Vue.filter('vat', (value) => {
    if (!value) {
      return '-';
    }
    return value;
  });
};
