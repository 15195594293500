import axios from 'axios';
import Service from './service';
import Downloadable from '../utils/downloadable';

export default class PrintService extends Service {
  print(attachmentName) {
    const { api } = this.client;
    const token = this.client.getStorage().get('token');
    const html = document.documentElement.innerHTML.toString();
    const bodyFormData = new FormData();
    bodyFormData.set('html', html);
    bodyFormData.set('attachment', attachmentName);
    return axios({
      url: `${api.baseUrl}v1/printPdf/`,
      method: 'POST',
      json: true,
      responseType: 'blob',
      data: bodyFormData,
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'multipart/form-data',
        'Authorization': token,
      },
    }).then((response) => {
      return new Downloadable(response.data, attachmentName);
    });
  }
}
