export default {
  beforeCreate() {
    const options = this.$options;
    if (this.$flairClient) {
      return;
    }
    if (options.$flairClient) {
      this.$flairClient = options.$flairClient;
    } else if (options.parent && options.parent.$flairClient) {
      this.$flairClient = options.parent.$flairClient;
    }
  },
};
