export default function (Component) {
      Component.options._i18n = {
        namespace: "components.Legend",
        translations: 























{
  "legend": "Risk levels"
}

      }
    }