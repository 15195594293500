import _ from 'lodash';
import { Entity } from '../jsonapi';
import Member, { UNKNOWN_MEMBER } from './member';
import Document, { UNKNOWN_DOCUMENT } from './document';
import Survey from './survey';
import Timeline from './timeline';

export default class DueDiligence extends Entity {

  constructor(document, loader) {
    super(document, loader);
    //TODO: Need to be fix doesn't work.
    this._members = _.keyBy(this.documents, 'id');
    this._documents = _.keyBy(this.documents, 'id');
  }

  getMember(id) {
    return _.find(this.members, (e) => e.id === id) || UNKNOWN_MEMBER;
  }

  isUnderEvaluation() {
    return this.currentStatus === 'UnderEvaluation';
  }

  isApproved() {
    return this.currentStatus === 'Approved';
  }

  isRejected() {
    return this.currentStatus === 'Rejected';
  }

  getDocument(id) {
    return _.find(this.documents, (e) => e.id === id) || UNKNOWN_DOCUMENT;
  }

  getSurvey(id) {
    return _.find(this.surveys, (e) => e.id === id) || UNKNOWN_DOCUMENT;
  }

  getRevisorName() {
    const event = _.find(this.timeline, (e) => {
      return e.type === 'DueDiligence.Event.ReviseRisk';
    });
    const member = this.getMember(_.get(event, 'createdBy.id'));
    return member.fullName;
  }

  getDecisionMaker() {
    const value = _.find(this.timeline, (e) => e.type === 'DueDiligence.Event.MakeDecision');
    const id = _.get(value, 'attributes.createdBy.id');
    return this.getMember(id);
  }

  getDecisionDate() {
    const value = _.find(this.timeline, (e) => e.type === 'DueDiligence.Event.MakeDecision');
    return _.get(value, 'attributes.createdAt');
  }

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.members': Member,
      'attributes.documents': Document,
      'attributes.timeline': Timeline,
      'attributes.surveys': Survey,
    }));
  }

}
