import _ from 'lodash';

export function setupFeatureFlags(flairClient) {
  const { AuthenticationService, AppService } = flairClient;

  const storage = flairClient.getStorage();

  const DEFAULTS = {
    userManagement() {
      const { me } = AuthenticationService;
      if (!me) {
        return false;
      }
      return me.isSuperadmin;
    },
    teamManagement() {
      const { me } = AuthenticationService;
      if (!me) {
        return false;
      }
      return me.isTeamManager();
    },
    creditSafe: () => {
      const { instance } = AppService;
      if (!instance) {
        return false;
      }
      return instance.supportsCreditSafe();
    },
    flairData: () => {
      const { instance } = AppService;
      if (!instance) {
        return false;
      }
      return instance.supportsFlairData();
    },
    devEnvironment: () => {
      if (process.env.NODE_ENV === 'development') {
        return true;
      }
      return false;
    },
    dueDiligence: () => {
      const { me } = AuthenticationService;
      if (!me) {
        return false;
      }
      return me.hasTeam();
    },
    teamPortfolio: () => {
      return true;
    },
    configuration: () => {
      return false;
    },
    subscription: () => {
      return false;
    },
    portfolio: () => {
      const { me } = AuthenticationService;
      if (!me) {
        return false;
      }
      return me.hasTeam();
    },
    portfolioStatistics: () => {
      return true;
    },
    companyNeighborhood: () => {
      return true;
    },
    geographicalGroup: () => {
      return true;
    },
    searchCompanies: () => {
      return true;
    },
    searchPeople: () => {
      return true;
    },
    companyOverview: () => {
      return true;
    },
    printpdf:  () => {
      return true;
    },
    customization:() => {
      return true;
    },
    internalAuthentication: () => {
      const { instance } = AppService;
      if (!instance) {
        return false;
      }
      return instance.hasInternalAuthentication();
    },
    matching: () => {
      return true;
    },
  };

  const saveFlags = (flags) => {
    storage.set('featureFlags', JSON.stringify(flags));
  };

  const getFlags = () => {
    const json = storage.get('featureFlags');
    let flags = Object.assign({}, DEFAULTS);
    try {
      flags = Object.assign({}, DEFAULTS, JSON.parse(json));
    } catch (e) {
      console.error('Error while parsing featureFlags from storage');
    }
    return flags;
  };

  const flagsValues = getFlags();

  const featureFlags = {

    enable(feature) {
      const override = {};
      override[feature] = true;
      saveFlags(Object.assign(getFlags(), override));
    },

    disable(feature) {
      const override = {};
      override[feature] = false;
      saveFlags(Object.assign(getFlags(), override));
    },

    isEnabled(feature) {
      if (!Object.prototype.hasOwnProperty.call(flagsValues, feature)) {
        throw new Error(`Unknown feature flag: '${feature}'`);
      }

      const providerFeatureDisabled = AppService.instance.supportsFeature(feature);

      const value = providerFeatureDisabled ? false : flagsValues[feature];
      if (typeof value === 'function') {
        return value();
      }

      return !!value;
    },

    reset() {
      saveFlags(DEFAULTS);
    },
  };

  if (typeof window !== 'undefined') {
    window.featureFlags = featureFlags;
  }

  return featureFlags;
}
