import { Entity } from '../jsonapi';
import Attachment from './attachment';

const JSON_API_TYPE = 'DueDiligence.Document';
export default class Document extends Entity {

  get size() {
    return this.attributes.sizeInBytes ? this.attributes.sizeInBytes / 1000 : '-';
  }

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
    }));
  }

  toRef() {
    return {
      type: JSON_API_TYPE,
      id: this.id,
    };
  }

  static fromFileAndAttachment(file, attachment) {
    if (!(file instanceof File)) {
      throw new Error('File expected');
    }
    if (!(attachment instanceof Attachment)) {
      throw new Error('Attachment expected');
    }

    return Entity.factor({
      data: {
        id: attachment.reference,
        type: JSON_API_TYPE,
        attributes: {
          contentType: attachment.contentType,
          name: file.name,
          sizeInBytes: file.size,
          secureDownloadUrl: attachment.links.self,
        },
      },
    }, Document);
  }

}

const UNKNOWN_DOCUMENT = Entity.factor({
  data: {
    id: null,
    type: JSON_API_TYPE,
    attributes: {
      name: 'Unknown',
      sizeInBytes: undefined,
      secureDownloadUrl: undefined,
    },
  },
}, Document);

export { UNKNOWN_DOCUMENT };
