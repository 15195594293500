import { helper } from './helper';
import Service from './service';
import { Collection, Entity } from '../jsonapi';
import emit from '../decorators/emit';
import { EnabledRiskIndicator } from '../entities';

export default class DataProviderService extends Service {

  ApiDataProviderService = this.client.api.DataProviderService;

  getDataProviders = helper(this.ApiDataProviderService.getDataProviders, Collection);

  getEnabledIndicators = helper(this.ApiDataProviderService.getEnabledIndicators, EnabledRiskIndicator)

  async isIndicatorNameTaken(riskIndicatorName) {
    try {
      await this.ApiDataProviderService.ensureIndicatorNameUnused({ id: riskIndicatorName });
      return true;
    } catch (err) {
      return false;
    }
  }

  @emit('customIndicator:created')
  createCustomRiskIndicator = helper(this.ApiDataProviderService.createCustomRiskIndicator, Collection);

  @emit('customIndicator:updated')
  updateCustomRiskIndicator = helper(this.ApiDataProviderService.updateCustomRiskIndicator, Entity);

  @emit('customIndicator:removed')
  removeCustomRiskIndicator = helper(this.ApiDataProviderService.removeCustomRiskIndicator, Entity);

  @emit('riskIndicators:updated')
  updateEnabledIndicators = helper(this.ApiDataProviderService.updateEnabledIndicators, EnabledRiskIndicator);

}
