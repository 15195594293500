import { Entity } from '../jsonapi';

export default class EnabledRiskIndicator extends Entity {

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.riskProfile': EnabledRiskIndicator,
    }));
  }
}
