import { Entity } from '../jsonapi';
import { SEARCH_MODE_COMPANY } from '../services/search.service';

export default class SearchState {
  constructor(client, defaultAttrs) {
    this.client = client;
    this.loadInitialState(defaultAttrs);
  }

  ensureLoaded() {
    return this.state.recent.promise;
  }

  get current() {
    return this.state.current;
  }

  get recent() {
    return this.state.recent;
  }

  get results() {
    return this.state.results;
  }

  hasSearch() {
    return this.current.attributes.q &&
           this.current.attributes.q.trim().length > 0;
  }

  getQ() {
    return this.current.attributes.q;
  }

  setAttributes(attrs) {
    // Clear push history, if any
    if (this._searchHistoryTimer) {
      clearTimeout(this._searchHistoryTimer);
    }
    // Change the current state
    this.current = this.current.withAttributes(attrs);
    this.refreshResultsAndHistory();
  }

  setQ(q) {
    this.setAttributes({ q });
  }

  setMode(mode) {
    this.setAttributes({ mode });
  }

  setFilters(filters) {
    this.setAttributes(filters);
  }

  clearSearch(defaultAttrs) {
    this.loadInitialState(defaultAttrs);
  }

  recentlySearched(search) {
    return this.recent && !!this.recent.items.find((s) => {
      return s.attributes.q === search.attributes.q &&
             s.attributes.mode === search.attributes.mode;
    });
  }

  // PRIVATE API ---------------------------------------------------------------

  loadInitialState(defaultAttributes = {}) {
    const base = Entity.factor({
      data: {
        type: 'Search',
        attributes: {
          mode: SEARCH_MODE_COMPANY,
          q: undefined,
          ...defaultAttributes,
        },
      },
    });
    this.mode = base.attributes.mode;
    this.state = {
      current: base,
      recent: this.client.SearchService.getSearchHistory({ mode: SEARCH_MODE_COMPANY }),
      results: this.client.SearchService.search(base),
    };
  }

  refreshResultsAndHistory() {
    // We don't allow search without a "q"
    if (!this.hasSearch()) {
      return;
    }
    // Refresh search results
    this.results = this.client.SearchService.search(this.current);

    // Refresh the recent search if the mode changes
    if (this.mode !== this.current.attributes.mode) {
      this.recent = this.client.SearchService.getSearchHistory({ mode: this.current.attributes.mode });
      this.mode = this.current.attributes.mode;
    }
    // Push history in 1.5sec and refresh the recent list
    const search = this.current.document.data;
    if (search.attributes.q && !this.recentlySearched(search)) {
      this._searchHistoryTimer = setTimeout(() => {
        this.client.SearchService
          .pushSearchHistory(search)
          .promise.then(() => {
            this.recent = this.client.SearchService.getSearchHistory({ mode: this.current.attributes.mode });
          });
      }, 1500);
    }
  }

  set current(c) {
    this.state.current = c;
  }

  set recent(r) {
    this.state.recent = r;
  }

  set results(r) {
    this.state.results = r;
  }
}
