import Service from './service';
import { Collection } from '../jsonapi';
import { Company, RiskIndicator } from '../entities';
import { transformToMap } from '../utils/transform';
import { helper } from './helper';
import { Entity } from '../jsonapi';
export default class EntitiesService extends Service {

  ApiService = this.client.api.EntitiesService;

  getCompanyRiskById = helper(this.ApiService.getCompanyRisk, RiskIndicator, (res) => {
    res.data.attributes.values = transformToMap(res.data, 'country', 'score');
    return res;
  });

  getCompanyById = helper(this.ApiService.getCompany, Company, (res) => {
    res.data.attributes.activity = transformToMap(res.data.attributes.activity, 'name', 'value');
    return res;
  });
  getCompanyHistoricalCreditRisk = helper(this.ApiService.getCompanyHistoricalCreditRisk, RiskIndicator);
  getCompanyHistoricalRiskIndicatorById = helper(this.ApiService.getCompanyHistoricalRiskIndicator, RiskIndicator);
  getPersonById = helper(this.ApiService.getPerson);
  getPersonHistoricalRiskIndicatorById = helper(this.ApiService.getPersonHistoricalRiskIndicator, RiskIndicator);

  /**
   * This service method support the facts that we sometimes look for
   * related companies for an "incomplete" person (we don't have an id).
   * In that case we do search on the person's name
   * and (for now) take the first result as a "i'm lucky" best match mode
   */
  getPersonRelatedCompanies(person) {
    const apiHelper = helper(this.ApiService.getPersonRelatedCompanies, Collection);

    if (person.id) {
      return apiHelper({ id: person.id });
    }

    const loader = () => {
      return this.client.SearchService.searchPeople({
        attributes: {
          q: person.fullName,
        },
      }).promise.then((results) => {
        if (results.items.length) {
          const imLucky = results.items[0];
          return this.ApiService.getPersonRelatedCompanies({ id: imLucky.id });
        }
      });
    };
    const c = Entity.factor(null, loader, Collection);
    c.load({}, false);
    return c;
  }

  getGroupById = helper(this.ApiService.getGroup);
  getCompanySubsidiaries = helper(this.ApiService.getCompanySubsidiaries, Collection);
  getCompanyShareholders = helper(this.ApiService.getCompanyShareholders, Collection);
  getCompanyManagement = helper(this.ApiService.getCompanyRelatedPeople, Collection);
  getCompanyDueDiligences = helper(this.ApiService.getCompanyDueDiligences, Collection);
  getGroupCompaniesByCountry = helper(this.ApiService.getGroupCompaniesByCountry, Collection);
  getEcovadisRiskRatingById = helper(this.ApiService.getEcovadisRiskRatingById, RiskIndicator);
}
