export default function (Component) {
      Component.options._i18n = {
        namespace: "components.ErrorModal",
        translations: 







































{
  "error": {
    "oops": "Oops",
    "somethingBadHappened": "An error occured. We are doing our best to remedy this as quickly as possible."
  },
  "download": "Download bug details",
  "ok": "Ok"
}

      }
    }