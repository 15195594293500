import { Entity } from '../jsonapi';
import RiskIndicator from './riskIndicator';

export default class DataProvider extends Entity {

  dress(itemDoc, subEntities = {}) {
    return super.dress(itemDoc, Object.assign({}, subEntities, {
      'attributes.riskIndicators': RiskIndicator,
    }));
  }
}
