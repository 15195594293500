export default function (Component) {
      Component.options._i18n = {
        namespace: "components.AnalysisSourceInfo",
        translations: 




{
  "unknown": "Unknown compliance source",
  "idUnknown": "According to {ds}",
  "known": "{ds} entry #{id}"
}

      }
    }