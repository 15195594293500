<template>
  <div
    v-show="shown"
    class="vadis-molecule-loader"
    :class="{'vadis-molecule-loader-global': global}"
  >
    <div class="valid-loader-ellipsis">
      <div /><div /><div /><div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    global: {
      type: Boolean,
      default: null,
      required: true,
    },
    shown: {
      type: Boolean,
      default: null,
      required: true,
    },
  },
};
</script>
