import Entity from './entity';
import Collection from './collection';

export default class Relationships {
  #entity;
  #cache;

  constructor(entity) {
    this.#entity = entity;
    this.#cache = entity.included.reduce((memo,i) => {
      memo[i.links.self] = this.factorOne({
        data: i,
        links: i.links,
      }, true);
      return memo;
    }, {});
    Object.keys(entity.data.relationships || {}).forEach((rel) => {
      this.defineOne(rel);
    });
  }

  defineOne(rel) {
    Object.defineProperty(this, rel, {
      enumerable: true,
      get() {
        const envelope = this.#entity.data.relationships[rel];
        const link = envelope.links.self;
        if (!this.#cache[link]) {
          this.#cache[link] = this.factorOne(envelope);
        }
        return this.#cache[link];
      },
    });
  }

  factorOne(envelope, loaded = false) {
    const loader = this.#entity.rebindLoader(envelope.links.self, true);
    if (envelope.data.type === 'Collection') {
      return new Collection(loaded ? envelope : undefined, loader);
    } else {
      return Entity.factor(loaded ? envelope : undefined, loader, envelope.data.type);
    }
  }
}
