import { Collection, Entity } from '../jsonapi';
import { helper } from './helper';
import Service from './service';
import emit from '../decorators/emit';

export default class AdminService extends Service {

  ApiService = this.client.api.AdminService;

  getDepartments(args) {
    return helper(this.ApiService.getDepartments, Collection)(args);
  }

  @emit('department:created')
  createDepartment = helper(this.ApiService.createDepartment, Entity);

  @emit('department:updated')
  updateDepartment = helper(this.ApiService.updateDepartment, Entity);

  @emit('department:removed')
  removeDepartment = helper(this.ApiService.removeDepartment, Entity);

  getTeams(args) {
    return helper(this.ApiService.getTeams, Collection)(args);
  }

  @emit('team:created')
  createTeam = helper(this.ApiService.createTeam, Entity);

  @emit('team:removed')
  removeTeam = helper(this.ApiService.removeTeam, Entity);

  @emit('team:updated')
  updateTeam = helper(this.ApiService.updateTeam, Entity);

  getUsers(args) {
    return helper(this.ApiService.getUsers, Collection)(args);
  }

  @emit('user:deleted')
  deleteUser = helper(this.ApiService.deleteUser, Entity);

  @emit('user:created')
  createUser(args) {
    return helper(this.ApiService.createUser, Entity, (res) => {
      this.emit('user:created', res);
    })(args);
  }

  @emit('user:updated')
  updateUser = helper(this.ApiService.updateUser, Entity);

  @emit('user:deleted')
  removeUserFromTeam = helper(this.ApiService.removeUserFromTeam, Entity);

  @emit('user:invited')
  sendUserInvite= helper(this.ApiService.sendUserInvite, Entity);

  getDataConsumption = helper(this.ApiService.getDataConsumption, Entity);

  getDataConsumptionMonthly = helper(this.ApiService.getDataConsumptionMonthly, Entity);

  getOrganizationInfo = helper(this.ApiService.getOrganizationInfo, Entity);

  getSubscription = helper(this.ApiService.getSubscription, Entity);
}
