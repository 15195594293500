<template>
  <div class="vadis-controller-view-switcher-bar">
    <ul>
      <template v-for="(item,i) in items">
        <li
          v-if="!item.features || $featureEnabled(item.features)"
          :key="i"
          :class="{ 'active-view': active === item.code }"
        >
          <a @click="setActive(item)">{{ translate([item.code]) }}</a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';
import { TranslationGroup } from 'flair-client';

export default {
  name: 'SwitcherBar',
  props: {
    items:{
      type: Array,
      default: () => [],
      required: true,
    },
    i18n: {
      type: TranslationGroup,
      default: null,
    },
    activeItem: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    active: null,
  }),
  mounted() {
    this.active = this.activeItem;
  },
  methods: {
    setActive(tab) {
      this.active = tab.code;
      this.$emit('click', tab);
    },
    translate(v) {
      if (this.i18n) {
        return _.get(this.i18n, v);
      } else {
        return v;
      }
    },
  },
};
</script>
