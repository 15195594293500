export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskAnalysisDetails",
        translations: 

































{
  "subjectName": "Name",
  "subjectPosition": "Position(s)",
  "publishedAt": "Date"
}

      }
    }