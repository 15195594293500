export default (Vue, flairClient) => {

  const precision = Vue.filter('precision', (value, decimal) => {
    if (!value) {
      return '-';
    }
    return value.toFixed(decimal);
  });

  Vue.filter('precision2', (value) => precision(value, 2));
};

