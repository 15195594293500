import { Entity } from '../jsonapi';

export const helper = (endpoint, clazz = Entity, transform, endpointRebind) => {
  return (args) => {
    const loader = () => {
      // TODO: this logic doesnt seem to do anything
      if (transform) {
        return endpoint(args);
      }
      return endpoint(args);
    };
    loader.rebind = (args) => {
      if (endpointRebind) {
        return endpointRebind.rebind(args);
      } else {
        return endpoint.rebind(args);
      }
    };
    const c = Entity.factor(null, loader, clazz, transform);
    // TODO implement a better way for errors
    // c.load({}, false);
    c.load({}, false);
    return c;
  };
};
