export default function (Component) {
      Component.options._i18n = {
        namespace: "components.RiskAnalysisList",
        translations: 



































































{
  "header": {
    "description": "{number} analysis linked to ",
    "lastUpdate": "Last updated "
  },
  "backToList": "Back to list",
  "publishedAt": "Published {publishedAt}"
}

      }
    }