import FlairClient, { I18NRemoteStaticSource } from 'flair-client';

export function createFlairClient() {
  const client = new FlairClient();
  let baseUrl;

  if (typeof window !== 'undefined') {
    baseUrl = window.location.origin;
    client.api.baseUrl = `${window.location.origin}/api/`;
  } else if (process.env.VUE_APP_API_BASEURL && process.env.VUE_APP_BASEURL) {
    baseUrl = process.env.VUE_APP_BASEURL;
    client.api.baseUrl = process.env.VUE_APP_API_BASEURL;
  } else {
    throw new Error('Unable to setup flair-client. Please set VUE_APP_API_BASEURL & VUE_APP_BASEURL');
  }

  client.I18NService.addSource([
    new I18NRemoteStaticSource({ baseUrl: `${baseUrl}/locales/ui/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'countries', baseUrl: `${baseUrl}/locales/countries/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'companyTypes', baseUrl: `${baseUrl}/locales/companyTypes/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'companyTypesBvD', baseUrl: `${baseUrl}/locales/companyTypesBvD/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'companySizes', baseUrl: `${baseUrl}/locales/companySizes/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'companyRisk', baseUrl: `${baseUrl}/locales/risk/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'dateTime', baseUrl: `${baseUrl}/locales/dateTime/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'locales', baseUrl: `${baseUrl}/locales/locales/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'nace2code', baseUrl: `${baseUrl}/locales/nace2codes/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'personFunctions', baseUrl: `${baseUrl}/locales/personFunction/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'providerRiskIndicator', baseUrl: `${baseUrl}/locales/providerRiskIndicator/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'dataProviderDescription', baseUrl: `${baseUrl}/locales/dataProviderDescription/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'riskIndicatorFAQ', baseUrl: `${baseUrl}/locales/riskIndicatorFAQ/{locale}.json` }),
    new I18NRemoteStaticSource({ namespace: 'dueDiligenceRoles', baseUrl: `${baseUrl}/locales/dueDiligenceRoles/{locale}.json` }),
  ]);

  return client;
}
