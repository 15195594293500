<script>
import { commentNode } from './utilities';
import _ from 'lodash';

export default {
  inserted(el, binding, vnode) {
    // For robustness purpose... if things don't work properly we
    // assume the user cannot see the component on which the directive
    // is being used
    const features = binding.arg.split(':');
    try {
      const member = _.find(vnode.context.dueDiligence.members, (e) => e.user.id === vnode.$flairClient.AuthenticationService.me.id);
      if (!member) {
        commentNode(el, vnode);
        return;
      }
      if (!features.includes(member.role.toLowerCase())) {
        commentNode(el, vnode);
      }
    } catch (e) {
      console.warn(`Error on v-feature:${features}`, e);
      commentNode(el, vnode);
    }
  },
};
</script>
