import _ from 'lodash';

const wrapFunction = (fn, eventName) => {
  return function(...args) {
    const result = fn.apply(this, args);
    if (result instanceof Promise) {
      result
        .then((res) => this.emit(eventName, res))
        .catch(() => {});
    } else if (result.promise instanceof Promise) {
      result
        .promise.then((res) => this.emit(eventName, res))
        .catch(() => {});
    } else {
      this.emit(eventName, result);
    }
    return result;
  };
};

const wrapMethod = (target, fnPointer, eventName) => {
  const originalFn = _.get(target, fnPointer);
  _.set(target, fnPointer, wrapFunction(originalFn, eventName));
  return target;
};

const wrapInitializer = (target, eventName) => {
  const originalInit = target.initializer;
  target.initializer = function(...args) {
    const originalFn = originalInit.call(this, args);
    if (!(originalFn instanceof Function)) {
      throw new Error('Incorrect usage of @emit decorator');
    }
    return wrapFunction(originalFn, eventName);
  };
  return target;
};

export default function(eventName) {
  return function(target) {
    if (target.kind === 'method') {
      const toWrap = target.descriptor.get ? 'descriptor.get' : 'descriptor.value';
      return wrapMethod(target, toWrap, eventName);
    }

    if (target.initializer) {
      return wrapInitializer(target, eventName);
    }

    throw new Error('Incorrect usage of @emit decorator');
  };
}
